let page = {
    "name": "Buy One Get One",
    "state": 1,
    "id": 5,
    "image":  "images/pages/6.png",
    "blocks": [
        {
            "justify": "center",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Buyone Banner Block",
            "color": "transparent",
            "using_image": true,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image":  "/images/page-template/shoes.jpg",
            "height": 650,
            "inner_blocks": [{
                "id": 31,
                "block_contents": [
                    {
                        "id": 81,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#FFFFFFFF",
                        "content": "BUY 1 GET 1 FREE",
                        "classes": "display-4",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "BUY 1 GET 1 FREE",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 82,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "BUY 1 get 1 free on selected products. See below for details.",
                        "classes": "display-2",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 83,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "light-blue lighten-1",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "SIGN UP",
                        "icon": "",
                        "type": "button",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                "name": "Buy One Banner Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
            "container": false,
            "order": 10
        },
        {
            "id": 21,
            "justify": "center",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Buyone Product Info Block",
            "color": "#E0E0E0",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 0,
            "inner_blocks": [
                {
                    "id": 32,
                    "block_contents": [
                        {
                            "id": 84,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "left",
                            "justify": "center",
                            "color": "white",
                            "content": "AMAZING SPORTS SHOES",
                            "classes": "display-1",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 12,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#263238",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": true,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        },
                        {
                            "id": 85,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "left",
                            "justify": "center",
                            "color": "white",
                            "content": "Buy one get one on these amazing shoes. These are the newest shoes we have that actually help you lose weight and motivate you to move your body. The results and research of these are amazing and we have attached our research for you to see the results.",
                            "classes": "body-1",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 12,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#263238",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        },
                        {
                            "id": 86,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "left",
                            "justify": "center",
                            "color": "light-blue lighten-1",
                            "content": "",
                            "classes": "white--text",
                            "pt": 8,
                            "pl": 0,
                            "pr": 0,
                            "pb": 8,
                            "mt": 12,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "",
                            "href": "#",
                            "value": "Add To Cart",
                            "icon": "mdi-cart",
                            "type": "button",
                            "large": false,
                            "rounded": true,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }
                    ],
                    "name": "Buyone Title, text button 6 col",
                    "block_image": "",
                    "pt": 8,
                    "pl": 0,
                    "pr": 0,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 6,
                    "md": 6,
                    "sm": 6,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "left",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }, {
                    "id": 33,
                    "block_contents": [
                        {
                            "id": 87,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "primary",
                            "content": "",
                            "classes": "",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 500,
                            "max_height": 280,
                            "text_color": "white--text",
                            "href": "#",
                            "value":  "images/unsorted/shoescut.png",
                            "icon": "",
                            "type": "image",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }],
                    "name": "Buyone image 6 col",
                    "block_image": "",
                    "pt": 8,
                    "pl": 0,
                    "pr": 0,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 6,
                    "md": 6,
                    "sm": 6,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }],
            "container": true,
            "order": 20
        },
        {
            "justify": "center",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Text Icon Block",
            "color": "white",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 0,
            "container": true,
            "order": 30,
            "inner_blocks": [
                {
                    "id": 2,
                    "block_contents": [{
                        "id": 17,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-truck-fast",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 20,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Fast and Free Shipping",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                        "classes": "body-2",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "4 Col Block",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }, {
                    "id": 3,
                    "block_contents": [{
                        "id": 18,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-bookmark-multiple",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 21,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Up to 80% Selected Merchandise",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                        "classes": "body-2",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "4 Col Block",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }, {
                    "id": 4,
                    "block_contents": [{
                        "id": 19,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-refresh",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 90,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Easy FREE 30 Day Returns",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                        "classes": "body-2",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "4 Col Block",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }],
        },
        {
            "id": 22,
            "justify": "end",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Discount Half Text Block",
            "color": "transparent",
            "using_image": true,
            "using_video": false,
            "full_height": false,
            "parallax": true,
            "background_image":  "/images/page-template/running2.jpg",
            "height": 500,
            "inner_blocks": [
                {
                    "id": 34,
                    "block_contents": [
                        {
                        "id": 50,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "justify": "center",
                        "align": "center",
                        "color": "",
                        "content": "LOYALTY PROGRAM",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                        {
                        "id": 51,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Join the loyalty program and collect points the more you buy. We will notify you by email when we have discounts as well as sending you special coupons when you sign up. Free to try and you can unsubscribe anytime.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                        {
                        "id": 52,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "FREE SIGN UP",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "FREE SIGN UP",
                        "icon": "",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": true,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                    ],
                    "name": "Buyone 6 col block 6 offset rgba back",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 6,
                    "md": 6,
                    "sm": 8,
                    "xs": 12,
                    "lg_offset": 6,
                    "md_offset": 6,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "#00000085",
                    "stretch": true,
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": "height: 100%; ",
                    "type": "regular",
                    "background_image": ""
                }
                ],
            "container": false,
            "order": 40
        },
        {
            "id": 23,
            "justify": "center",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Buyone Text Button Banner",
            "color": "#ffffff",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 350,
            "inner_blocks": [
                {
                    "id": 35,
                    "block_contents": [{
                        "id": 88,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "JOIN OUR LOYALTY PROGRAM AND SAVE BIG",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                        {
                        "id": 89,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "light-blue lighten-1",
                        "content": "",
                        "classes": "white--text",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "",
                        "href": "#",
                        "value": "JOIN NOW",
                        "icon": "mdi-bookmark-multiple",
                        "type": "button",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                    ],
                    "name": "Buyone 12 Cols banner button block",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "background_has_image": true,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }],
            "container": true,
            "order": 50
        },
        {
            "id": 8,
            "justify": "center",
            "align": "center",
            "category": 1,
            "block_image": "",
            "name": "Footer Block",
            "color": "#263238",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 0,
            "inner_blocks": [
                {
                    "id": 14,
                    "block_contents": [{
                        "id": 42,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "LeadSlide",
                        "classes": "small",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#ffffff",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "Footer Block Left",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }, {
                    "id": 15,
                    "block_contents": [{
                        "id": 44,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-facebook",
                        "type": "button",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": true,
                        "fab": true,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 45,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-twitter",
                        "type": "button",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": true,
                        "fab": true,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "id": 46,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-linkedin",
                        "type": "button",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": true,
                        "fab": true,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "Footer Block Center",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "list",
                    "background_image": ""
                }, {
                    "id": 16,
                    "block_contents": [{
                        "id": 43,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Copyright © LeadSlide",
                        "classes": "small",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#ffffff",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                    "name": "Footer Block Right",
                    "block_image": "",
                    "pt": 8,
                    "pl": 8,
                    "pr": 8,
                    "pb": 8,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": null,
                    "type": "regular",
                    "background_image": ""
                }],
            "container": false,
            "order": 60
        }
        ]
}

export default page;
