export default [
    {
        cols:6,
        using_image: true,
        hide_on_mobile: true,
        image: '',
        color: '#FFFFFF',
        pt: 0,
        pb: 0,
        pl: 0,
        pr: 0,
        contents: [
            {
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "justify": "center",
                "align": "center",
                "color": "",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#333333",
                "href": "#",
                "value": "/media/preload/food/lyfe-fuel-V8RyrzwduR8-unsplash.jpg",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            },
        ]
    },
    {
        cols:6,
        using_image: false,
        hide_on_mobile: false,
        color:'#ffffff',
        use_image_on_mobile: true,
        background_image: '/media/preload/food/lyfe-fuel-V8RyrzwduR8-unsplash.jpg',
        contents: [
            {
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "justify": "center",
                "align": "center",
                "color": "",
                "content": "<p class=\"headline\"><span style=\"font-weight: bold; font-family: font-family: Open Sans, sans-serif; color: #424242;\">GIVEAWAY</span></p>",
                "pt": 0,
                "pl": 3,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 3,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#333333",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            },
            {
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "justify": "center",
                "align": "left",
                "color": "",
                "content": "<p class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif; color: #424242;\">Signup for a chance to win a 2 month supply of our Vegan Nutrient Shake.</span></p>",
                "classes": "body-1",
                "pt": 0,
                "pl": 3,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 6,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#808080",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            },

            {
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 5,
                "pr": 5,
                "pb": 5,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFFF",
                "href": "#",
                "value": "Submit",
                "icon": "",
                "type": "form",
                "items": [
                    {
                        label: 'Name',
                        icon: null,
                        required: true,
                        minLength: 5,
                        maxLength: 20,
                        type: 'text',
                        solo: true,
                        model: "",
                        rules: [
                            v => !!v || 'Name is required',
                            v => v.length <= 10 || 'Name must be less than 10 characters',
                        ]
                    },
                    {
                        label: 'Email',
                        icon: null,
                        required: true,
                        solo: true,
                        minLength: 5,
                        maxLength: 20,
                        type: 'email',

                        model: "",
                        rules: [
                            v => !!v || 'E-mail is required',
                            v => /.+@.+/.test(v) || 'E-mail must be valid',
                        ]
                    },
                    {
                        "font-family": "Open Sans, sans-serif",
                        label: 'Sign Up',
                        icon: null,
                        rounded: true,
                        block: true,
                        outlined: false,
                        color:'#424242',
                        text_color: "#FFFFFF",
                        type: 'button',
                        rules: []
                    },
                ],
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": true,
                "show_popup": false,
                "block": false
            },
        ]
    }
]