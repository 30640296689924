import axios from "axios";

const allowedTypes = [
    'text',
    'button',
];


export const aiRules = {
    "title": {
        "type": "title",
        "minLength": 3,
        "maxLength": 20
    },
    "subtitle": {
        "type": "subtitle",
        "minLength": 6,
        "maxLength": 50
    },
    "introHeading": {
        "type": "introHeading",
        "minLength": 2,
        "maxLength": 15
    },
    "intro": {
        "type": "intro",
        "minLength": 200,
        "maxLength": 550
    },
    "text": {
        "type": "text",
        "minLength": 150,
        "maxLength": 350
    },
    "paragraph": {
        "type": "paragraph",
        "minLength": 290,
        "maxLength": 750
    },
    "button": {
        "type": "button",
        "minLength": 2,
        "maxLength": 10
    },
}

const fillAiTypes = (aiPayload, aiModel) => {
    const aiType = aiModel.type;
    const item = aiModel.item;
    const campaign_step = aiModel.campaign_step;
    let added = 0;
    if(aiType === 'campaign') {
        let contents = item.block_contents;

        switch(campaign_step) {
            case 0:
                contents = item.block_contents;
                break;
            case 1:
                contents = item.contents;
                break;
            case 2:
                contents = item.block_contents;
                break;
        }

        contents.forEach(content => {
            // if content.type not in allowedTypes then continue
            if(!allowedTypes.includes(content.type)) {
                return;
            }

            /**
             * If the content is empty and the value is not empty, then set the content to the value
             */
            let checkContent = content.content;
            if((content.content === '' && content.value !== '') || typeof content.content === 'undefined') {
                checkContent = content.value;
            }

            if(checkContent === '') {
                checkContent = "this, is a test for a min number of words."
            }

            const parser = new DOMParser();
            const dom = parser.parseFromString(checkContent, 'text/html');
            const text = dom.body.textContent || "";
            const words = text.trim().split(/\s+/).filter(word => word.length > 0);


            // get the word length of the content
            const wordLength = words.length;

            // if content.type === text
            if(content.type === 'text') {
                let aiType = 'text';
                // if checkContent contains h1 or h2 tags then set aiType to title
                if(checkContent.includes('<h1') || checkContent.includes('<h2')) {
                    aiType = 'title';
                }

                // if checkContent contains h3 - h6 tags then set aiType to subtitle
                if(checkContent.includes('<h3') || checkContent.includes('<h4') || checkContent.includes('<h5') || checkContent.includes('<h6')) {
                    aiType = 'subtitle';
                }

                aiPayload.push(aiRules[aiType]);
            }

            if(content.type === 'button') {
                aiPayload.push(aiRules.button);
            }

            if(content.type === 'icon') {
                aiPayload.push({
                    type: 'icon'
                });
            }

        });
    } else if(aiType === 'pdf') {
        let pdfBlocks = [];

        if(state.page_index !== null && state.block_index  !== null) {
            const type = state.item.type;
            if(type === 'text') {
                /**
                 * Checks the content for various tags and sets the pdfBlocks accordingly
                 * @type {string|*}
                 */
                const checkContent = state.item.typeSettings.value;
                let content_rules = aiRules.paragraph;

                pdfBlocks = [];
                if(checkContent.includes('<h1') || checkContent.includes('<h2') || checkContent.includes('headline')) {
                    content_rules = aiRules.title;
                } else if(checkContent.includes('<h3') || checkContent.includes('<h4') || checkContent.includes('<h5') || checkContent.includes('<h6') || checkContent.includes('title')) {
                    content_rules = aiRules.subtitle;
                }

                pdfBlocks.push(content_rules);
            }

        } else {
            pdfBlocks = [
                aiRules.title,
                aiRules.subtitle,
                aiRules.introHeading,
                aiRules.intro,
                aiRules.title,
                aiRules.subtitle,
                aiRules.paragraph,
                aiRules.paragraph
            ];
        }

        // add pdfBlocks to payload
        aiPayload.push(...pdfBlocks);
    }
}

const state = {
    show: false,
    item: {},
    index: null,
    block_index: null,
    inner_block_index: null,
    page_index: null,
    type: 'campaign'
}

const getters = {
    aiModel: state => {
        return state;
    },
}

const actions = {
    setAiModel: (store, payload) => {
        store.commit('mutateAiModel', payload);
    },
    getContentTypes: (store, payload) => {
        const aiModel = payload.model;
        const aiPayload = [];
        fillAiTypes(aiPayload, aiModel);
        return aiPayload;
    },
    generateText: (store, payload) => {
        const options = payload.options;
        const aiModel = payload.model;

        const purpose = payload.purpose;
        if(!aiModel.campaign_step || typeof aiModel.campaign_step === 'undefined') {
            aiModel.campaign_step = 0;
        }

        let aiPayload = [
            {
                type: 'purpose',
                text: purpose
            }
        ];
        if(options.length) {
            aiPayload = [
                ...aiPayload,
                ...options
            ];
        } else {
            fillAiTypes(aiPayload, aiModel);
        }

        return new Promise((resolve, reject) => {
            axios.post('business/generate-text', aiPayload, {timeout: 3000000})
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

const mutations = {
    mutateAiModel: (state, payload) => {
        state.page_index = null;
        state.item = payload.block;
        state.block_index = payload.block_index;
        state.inner_block_index = payload.inner_block_index;
        state.show = payload.show;
        // if payload.type is not null then set state.type to payload.type
        if(payload.type !== null) {
            state.type = payload.type;
        } else {
            state.type = 'campaign';
        }

        if(typeof payload.page_index !== undefined && payload.page_index !== null) {
            state.page_index = payload.page_index;
        }

        if(payload.step !== null) {
            state.campaign_step = payload.step;
        } else {
            state.campaign_step = 0;
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}