import outer_blocks from "../LeadSlide/Blocks/OuterBlocks"
import TinyMceConfig from "../LeadSlide/Blocks/TinyMceConfig";

const state = {
    outer_blocks: outer_blocks,
    inner_blocks: [],
    image_block: {
        active: false,
        item: {}
    },
    outer_block: {
        index: null,
        active: false,
        item: {}
    },
    form_block: {
        index: null,
        active: false,
        item: {}
    },
    icon_block: {
        active: false,
        item: {}
    },

    editorConfig: TinyMceConfig,
    modifying_theme: false,
}

const actions = {
    setModifyTheme: (store, payload) => {
        store.commit('mutateModifyTheme', payload);
    },
    setIconBlock: (store, payload) => {
        console.log("Set Icon Block");
        store.commit('iconBlockMutation', payload);
    },
    setImageBlock: (store, payload) => {
        store.commit('imageBlockMutation', payload);
    },
    setOuterBlock: (store, payload) => {
        store.commit('outerBlockMutation', payload);
    },
    setFormBlock: (store, payload) => {
        store.commit('formBlockMutation', payload);
    },
    resetIconBlock: (store) => {
        store.commit('resetIconMutation');
    },
    resetImageBlock: (store) => {
        store.commit('resetImageMutation');
    },
    resetOuterBlock: (store) => {
        store.commit('resetOuterMutation');
    },
    setConfigStyles: (store, payload) => {
        store.commit('mutateConfigStyles', payload);
    },
}

const getters = {
    outerBlockIndex: state => {
        return state.outer_block.index;
    },
    modifying_theme: state => {
        return state.modifying_theme;
    },
    outer_blocks: state => {
        return state.outer_blocks;
    },
    iconBlock: state => {
        return state.icon_block;
    },
    imageBlock: state => {
        return state.image_block;
    },
    outerBlock: state => {
        return state.outer_block;
    },
    formBlock: state => {
        return state.form_block;
    },
    editorConfig: state => {
        return state.editorConfig;
    }
}

const mutations = {
    mutateModifyTheme: (state, bool) => {
        state.modifying_theme = bool;
    },
    mutateConfigStyles: (state, styles) => {
        state.editorConfig.font_formats = styles.fonts;
        state.editorConfig.color_map = styles.colors;
    },
    imageBlockMutation: (state, payload) => {
        // Sets the main image model settings
        state.image_block.active = payload.active;
        state.image_block.item = payload.item;
    },
    iconBlockMutation: (state, payload) => {
        // Sets main icon block settings
        state.icon_block.active = payload.active;
        state.icon_block.item = payload.item;
    },
    outerBlockMutation: (state, payload) => {
        // Sets main outer block settings
        state.outer_block.active = payload.active;
        state.outer_block.item = payload.item;
        state.outer_block.index = payload.index;
        state.outer_block.top = payload.top;
    },
    formBlockMutation: (state, payload) => {
        state.form_block.active = payload.active;
        state.form_block.item = payload.item;
        // state.form_block.index = payload.index;
    },
    resetIconMutation: (state) => {
        // Closes the icon modal
        state.icon_block = {
            active: false,
            item: {}
        }
    },
    resetImageMutation: (state) => {
        // Closes the icon modal
        state.image_block = {
            active: false,
            item: {}
        }
    },
    resetOuterMutation: (state) => {
        // close outerblock modal
        state.outer_block = {
            index: null,
            active: false,
            item: {},
            top: state.outer_block.top
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}