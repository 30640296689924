export default {
    "id": 1, "name": "Sports Sale", "state": 1, "image":  "images/pages/1.png", "blocks": [{
        "id": 1,
        "category": 1,
        "block_image": "",
        "name": "Sports Banner Block",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "container": false,
        "background_image":  "images/templates/sports/banner.jpg",
        "height": 550,
        "inner_blocks": [{
            "id": 1,
            "block_contents": [
                {
                    "id": 13,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "Great Deals",
                    "classes": "display-3",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 14,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "ON SELECT PRODUCTS",
                    "classes": "display-2",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 15,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "ENDS JULY 19",
                    "classes": "headline",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 16,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "#69F0AE",
                    "content": "SHOP DEALS",
                    "classes": "headline",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 185,
                    "min_height": 55,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "white",
                    "href": "#",
                    "value": "Sign Up",
                    "icon": "",
                    "type": "button",
                    "large": true,
                    "rounded": true,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }
            ],
            "name": "12 Cols",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": true,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
    }, {
        "id": 2,
        "category": 1,
        "block_image": "",
        "name": "Text Icon Block",
        "color": "white",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 2,
            "block_contents": [{
                "id": 17,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-truck-fast",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 20,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Fast and Free Shipping",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 3,
            "block_contents": [{
                "id": 18,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-bookmark-multiple",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 21,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Up to 80% Selected Merchandise",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 4,
            "block_contents": [{
                "id": 19,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-refresh",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 90,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Easy FREE 30 Day Returns",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    }, {
        "id": 3,
        "category": 1,
        "block_image": "",
        "name": "Product Block",
        "color": "#ececec",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 5,
            "block_contents": [{
                "id": 23,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "primary",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 500,
                "max_height": 170,
                "text_color": "white--text",
                "href": "#",
                "value":  "images/page-template/baseball.jpg",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 26,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Big Discounts On Baseball Equipment",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 29,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Limited Supply Available",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": true,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 30,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#E53935",
                "content": "SHOP DEALS",
                "classes": "no-radius",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white",
                "href": "#",
                "value": "SHOP DEALS",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": true
            }],
            "name": "4 Col Block product sports",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "white",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 6,
            "block_contents": [{
                "id": 24,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "primary",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 500,
                "max_height": 170,
                "text_color": "white--text",
                "href": "#",
                "value":  "images/page-template/bball.jpg",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 27,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Big Discounts On Basketball Equipment",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 29,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Limited Supply Available",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": true,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 30,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#E53935",
                "content": "SHOP DEALS",
                "classes": "no-radius",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white",
                "href": "#",
                "value": "SHOP DEALS",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": true
            }],
            "name": "4 Col Block product sports",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "white",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 7,
            "block_contents": [{
                "id": 25,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "primary",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 500,
                "max_height": 170,
                "text_color": "white--text",
                "href": "#",
                "value":  "images/page-template/soccer.jpg",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 28,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Big Discounts On Soccer Equipment",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 29,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Limited Supply Available",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": true,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 30,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#E53935",
                "content": "SHOP DEALS",
                "classes": "no-radius",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white",
                "href": "#",
                "value": "SHOP DEALS",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": true
            }],
            "name": "4 Col Block product sports",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "white",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    }, {
        "id": 4,
        "category": 1,
        "block_image": "",
        "name": "Half Text Block",
        "color": "",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image":  "images/page-template/running.jpg",
        "height": 450,
        "inner_blocks": [{
            "id": 8,
            "block_contents": [{
                "id": 31,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "SUMMER CLEARANCE",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 32,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "These are the newest shoes we have that actually help you lose weight and motivate you to move your body. The results and research of these are amazing and we have attached our research for you to see the results.",
                "classes": "body-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 33,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "white",
                "content": "SHOP DEALS",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "SHOP DEALS",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": true,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "6 col block 6 offset rgba back",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 6,
            "md": 6,
            "sm": 8,
            "xs": 12,
            "lg_offset": 6,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "rgba(0,0,0,0.5)",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": "height: 100%; ",
            "type": "regular",
            "background_image": ""
        }],
        "container": false
    }, {
        "id": 5,
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Left",
        "color": "#ececec",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 9,
            "block_contents": [{
                "id": 34,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 200,
                "max_height": 200,
                "text_color": "white--text",
                "href": "#",
                "value":  "images/unsorted/testimonial-thumbs-climber.png",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 col, testimonial 1 image",
            "block_image": "",
            "pt": 0,
            "pl": 0,
            "pr": 0,
            "pb": 0,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": "height: 100%; ",
            "type": "regular",
            "background_image": ""
        }, {
            "id": 11,
            "block_contents": [{
                "id": 36,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "I bought all my climbing equipment at 80% off on black Friday. The savings was so amazing so I extended my vacation for a week!",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 38,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Alex, Climbing Enthusiast",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#00E5FF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "8 col testimony left text",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 8,
            "md": 8,
            "sm": 8,
            "xs": 12,
            "lg_offset": 6,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": " ",
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    }, {
        "id": 6,
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Right",
        "color": "#ececec",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 10,
            "block_contents": [{
                "id": 35,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 200,
                "max_height": 200,
                "text_color": "white--text",
                "href": "#",
                "value":  "images/unsorted/testimonial-thumbs-ski.png",
                "icon": "",
                "type": "image",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 col, right testimonial 1 image",
            "block_image": "",
            "pt": 0,
            "pl": 0,
            "pr": 0,
            "pb": 0,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": "height: 100%; ",
            "type": "regular",
            "background_image": ""
        }, {
            "id": 12,
            "block_contents": [{
                "id": 37,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "A great day on the slopes thanks to my new gear. The customer service and recommendations were so hopeful for a novice like me.",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 39,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Robbie, Outdoor Nerd",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#00E5FF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "8 col testimony right text",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 8,
            "md": 8,
            "sm": 8,
            "xs": 12,
            "lg_offset": 6,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": " ",
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    }, {
        "id": 7,
        "category": 1,
        "block_image": "",
        "name": "Text Button Banner",
        "color": "#7cc576",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 300,
        "inner_blocks": [{
            "id": 13,
            "block_contents": [{
                "id": 40,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "LEARN ABOUT OUR PRODUCT RESEARCH",
                "classes": "display-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#ffffff",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 41,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "Learn More",
                "icon": "mdi-leaf",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": true,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "12 Cols banner button block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": true,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    }, {
        "id": 8,
        "category": 1,
        "block_image": "",
        "name": "Footer Block",
        "color": "#263238",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 14,
            "block_contents": [{
                "id": 42,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "LeadSlide",
                "classes": "small",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#ffffff",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Left",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 15,
            "block_contents": [{
                "id": 44,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-facebook",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 45,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-twitter",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 46,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-linkedin",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Center",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "list",
            "background_image": ""
        }, {
            "id": 16,
            "block_contents": [{
                "id": 43,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Copyright © LeadSlide",
                "classes": "small",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#ffffff",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Right",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": false
    }]
}
