<template>
  <v-dialog
      v-model="loader.loading"
      hide-overlay
      persistent
      width="300"
  >
    <v-card
        color="black"
        dark
        class="pt-3"
    >
      <v-card-text>
        {{ loader.message }}
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'Loader',
  components: {
  },
  computed:{
    ...mapGetters([
        'loader'
    ])
  },
  data () {
    return {
      //
    }
  }
}
</script>