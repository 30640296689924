let debounceTimeout = null;

function debounce(fn, delay = 300) {
    return function (...args) {
        return new Promise((resolve, reject) => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
            debounceTimeout = setTimeout(() => {
                // Ensure the function call handles promises correctly
                Promise.resolve(fn(...args))
                    .then(resolve)
                    .catch(reject);
            }, delay);
        });
    };
}

// Debounce function is registered globally in your plugin
export default {
    install(Vue) {
        Vue.prototype.$debounce = debounce;
    }
};
