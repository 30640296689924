// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'

// Routes
import paths from './paths'
import axios from "axios";
import {apiHandler} from "../plugins/axios";

Vue.use(Router);

// Create a new router
const router = new Router({
    mode: 'history',
    routes: paths,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        if (to.hash) return {selector: to.hash};
        else return {x: 0, y: 0}
    }
});

const revokeAccess = () => {
  localStorage.removeItem('user');
  store.state.user = undefined;
  store.state.user_groups = undefined;
  store.state.user_groups_names = undefined;
  store.state.user_permissions = undefined;
  store.state.$http.defaults.headers.common["Authorization"] = undefined;
  localStorage.removeItem('access_token');
  store.state.accessToken = null;
}
const verifyToken = () => {
  axios.post(store.state.endpoints.verifyJWT, {
    token: localStorage.getItem('access_token')
  })
      .then((response) => {
        // success token matches
        store.state.user = response.data.user;
        // remove local storage and reset it
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(response.data.user));
      })
      .catch((error) => {
        console.log(error);
        console.log("ERROR using  token but not this one, cancel auth");
        if (error.response && error.response.status === 403) {
          revokeAccess();
        }
      });
}
router.beforeEach((to, from, next) => {
  const shopifyAccessToken = to.query.access_code;

  if (shopifyAccessToken && to.name !== 'shopifyAuth') {
    const shopifyShop = to.query.shop;
    return next({ path: `/shopify/auth/${shopifyAccessToken}?shop=${shopifyShop}` });
  }

  if(isUserLoggedIn()) {
    /**
     * Verify the token before each request to ensure that it is still valid
     */
    verifyToken();
  }

  if (!to.matched.some(record => record.meta.requiresAuth)) {
    /**
     * If the route does not require authentication, just continue
     */
    return next();
  }

  if(!isUserLoggedIn()) {
    /**
     * If the user is not logged in, redirect to login
     */
    return redirectToLogin(next, to);
  }

  let time_set = JSON.parse(localStorage.getItem('storage_time'));
  if (!time_set || typeof time_set == 'undefined') {
    /**
     * When a user is logged in, but the storage time is not set
     * the user instance is invalid and should be revoked and redirected to login
     */
    revokeAccess();
    return redirectToLogin(next, to);
  }
  let dateString = time_set.timestamp;
  let now = new Date().getTime().toString();

  let diff = (now - dateString) / 1000;
  diff /= (60 * 60);
  let compare_hours = Math.abs(Math.round(diff));
  if(isTokenExpired(compare_hours, time_set)) {
    /**
     * If the token is expired, revoke access and redirect to login
     */
    console.log("Token Expired");
    console.log("Comp Hours: " + compare_hours);
    console.log("Time Set: " + time_set);
    revokeAccess();
    return redirectToLogin(next, to);
  }

  if (compare_hours >= time_set.refresh_hours) {
    // TODO: Change refresh to return promise
    refreshToken(dateString);
  }

  if(!to.matched.some(record => record.meta.authGroups)) {
    /**
     * If the route does not require a specific group, just continue
     */
    return next();
  }

  let user = JSON.parse(localStorage.getItem('user'));
  if (store.user) {
    user = store.user;
  }
  let required_groups = to.matched.slice().reverse().find(r => r.meta && r.meta.authGroups).meta.authGroups;
  let matching_groups = user.groups.filter(arr1Item => required_groups.includes(arr1Item)); //returns array of items in the array
  let access_allowed = false;

  if (required_groups.length === 0) {
    // Will be used to get new user dashbaord
    access_allowed = true;
  }

  user.groups.forEach(function (n) {
    if (required_groups.find(o => o === n.id)) {
      access_allowed = true;
    }
  });

  if (to.meta.requires_membership && to.path !== '/c-panel/billing') {
    if (user.trial_days <= 0 && !user.is_subscribed) {
      console.log("Trial Days are 0");
      return window.location = '/c-panel/billing';
    }
  }

  if(!access_allowed) {
    /**
     * If the user does not have the required group, redirect to dashboard
     */
    return InvalidPermission(next, to);
  }

  /**
   * If the user has the required group, continue
   */
  return next();
});

const isAuthRequired = (route) => {
  return route.matched.some(record => record.meta.requiresAuth);
}

const isUserLoggedIn = () => {
  return localStorage.getItem('access_token') !== null;
}

const isTokenExpired = (compare_hours, time_set) => {
  return (compare_hours >= time_set.max_hours || time_set.current_refresh_count >= time_set.refresh_max);
}

const refreshToken = (dateString) => {
  axios.post(store.state.endpoints.refreshJWT, {
    token: localStorage.getItem('access_token')
  })
      .then((response) => {
        let data = response.data;
        localStorage.setItem('access_token', data.token);
        store.state.accessToken = data.token;
        let time_obj = {
          refresh_hours: 2,
          refresh_max: 3,
          max_hours: 6,
          current_refresh_count: (dateString.current_refresh_count + 1),
          timestamp: new Date().getTime()
        };
        localStorage.setItem('storage_time', JSON.stringify(time_obj));
        localStorage.setItem('user', JSON.stringify(data.user));
      })
      .catch((error) => {
        console.log(error)
      })
}

const redirectToLogin = (next, route) => {
  next({ path: '/login', params: { nextUrl: route.fullPath } });
}

const redirectToBilling = (next, route) => {
  next({ path: '/c-panel/billing', params: { nextUrl: route.fullPath } });
}

const redirectToDashboard = (next, route) => {
  next({ path: '/c-panel/dashboard', params: { nextUrl: route.fullPath } });
}

const InvalidPermission = (next, route) => {
  next({ path: '/errors/401', params: { nextUrl: route.fullPath } });
}




Vue.use(Meta);

export default router
