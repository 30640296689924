// https://vuex.vuejs.org/en/mutations.html

import axios from "axios";

let mutations = {

    updateToken: (state, data) => {
        localStorage.setItem('access_token', data.token);
        localStorage.setItem('shopify_user', data.shopify_user);
        state.shopify_user = data.shopify_user;
        state.accessToken = data.token;

        //Update the axios token every time the actual token is refreshed
        state.$http.defaults.headers.common['Authorization'] =  localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        let time_obj = {
            refresh_hours: 2,
            refresh_max:3,
            max_hours: 6,
            current_refresh_count:0,
            timestamp: new Date().getTime()
        };
        localStorage.setItem('storage_time', JSON.stringify(time_obj) );
        localStorage.setItem('user', JSON.stringify(data.user));
        state.user = data.user;
    },
    updateLinkLogin: (state, data) =>{
        /**
         * First get existing_data and set previous_details_
         */
        /**
         * Now update the existing info
         */
        localStorage.setItem('access_token', data.token);
        state.accessToken = data.token;

        //Update the axios token every time the actual token is refreshed
        state.$http.defaults.headers.common["Authorization"] = localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        let time_obj = {
            refresh_hours: 2,
            refresh_max:3,
            max_hours: 6,
            current_refresh_count:0,
            timestamp: new Date().getTime()
        };

        localStorage.setItem('storage_time', JSON.stringify(time_obj) );
        localStorage.setItem('user', JSON.stringify(data.user));
        state.user = data.user;
    },
    removeToken:(state, context) => {
        state.$http.defaults.headers.common["Authorization"] =  undefined;
        localStorage.removeItem('access_token');
        state.accessToken = null;
    },
    removeUser:(state) => {
        localStorage.removeItem('user');
        state.user = undefined;
        state.user_groups = undefined;
        state.user_groups_names = undefined;
        state.user_permissions = undefined;
    },
    updateUserSites: (state, sites) => {
        state.user.sites = sites;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    removeBufferUser:(state) => {
        localStorage.removeItem('user');
        state.user = undefined;
        state.user_groups = undefined;
        state.user_groups_names = undefined;
        state.user_permissions = undefined;
    },
    setResponsive:(state, width) =>{
        state.responsive = (width < 991);
    },
    setSubscriptionPlans:(state, plans) =>{
        state.plans = plans;
    },
    updateUserProfileUrl: (state, url) => {
        state.user.profile_user.leadslide_url = url;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
};

export default mutations;

