export default [
    {
        "category": 1,
        "block_image": "",
        "name": "Banner Block",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "/media/images/templates/vegan/brenda-godinez-CrK843Pl9a4-unsplash.jpg",
        "height": 0,
        "inner_blocks": [
            {
            "block_contents": [
                {
                    "id": 55,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "shadow": true,
                    "content": "<h1 style=\"text-align:center;\" class=\"text-h2 text-sm-h1\"><span style=\"font-weight: bold; font-family: font-family: Open Sans, sans-serif;\">VEGAN SHAKE GIVEAWAY</span></h1>",
                    "classes": "",
                    "pt": 12,
                    "pl": 0,
                    "pr": 0,
                    "pb": 12,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 56,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "<p style=\"text-align:center;\" class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif;\">Daily vegan shake giveaway. 3 month supply*.</span></p>",
                    "classes": "body-2",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 57,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 12,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "href": "#",
                    "icon": "",
                    "type": "button",
                    "color": "#424242",
                    "content": "<span style=\"font-family: font-family: Open Sans, sans-serif;\">Sign Up</span>",
                    "text_color": "#FFFFFF",
                    "value": "<span style=\"font-family: font-family: Open Sans, sans-serif;\">Sign Up</span>",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }
            ],
            "name": "Creative Banner Block",
            "block_image": "",
            "pt": 12,
            "pl": 8,
            "pr": 8,
            "pb": 12,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }
        ],
        "container": false,
        "pt": 12,
        "pb": 12,
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Text Banner",
        "color": "#cdfcfc",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<h4 class=\"display-1\" style=\"text-align:center; font-weight: bold;\"><span style=\"color: #424242; font-family: font-family: Open Sans, sans-serif;\">SHAKE GIVEAWAY</span></h4>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align:center;\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">Sign up and have a chance to win. Also, get 20% off your first order just for signing up!</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 7,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                ],
                "name": "12 Cols banner button block",
                "block_image": "",
                "pt": 12,
                "pl": 8,
                "pr": 8,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
        "container": true,
        "pt": 12,
        "pb": 12,
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Text Icon Block",
        "color": "#cdfcfc",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 17,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 6,
                        "pr": 6,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 20,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center; font-weight: bold;\" class=\"body-1\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">SIGN UP AND GET A CHANCE TO WIN</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-2 \"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">Signup and get a chance to win 3 month supply of our Vegan Shake. <i>(Enough for 90 serves)</i></span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 12,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "id": 18,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-close",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center; font-weight: bold;\" class=\"body-1\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">GET 25% OFF YOUR FIRST ORDER</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-2\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">We are so confident that you'll live our products that we are also offering a 25% coupon for your first order on all our products!</span></p>",
                        "classes": "body-2",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "id": 4,
                "block_contents": [
                    {
                        "id": 19,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-airplane",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 90,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-1\"><span style=\"font-weight: bold; color: #424242; font-family: ont-family: Open Sans, sans-serif;\">GET MORE FREE STUFF AND DISCOUNTS</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-2\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">We will also send monthly offers and more discounts for joining our team just sign up. You can remove your email anytime also.</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true,
        "pt": 1,
        "pb": 12
    },


    {
        "category": 1,
        "block_image": "",
        "name": "Inline Form Image",
        "color": "#f8f8f8",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 60,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"headline\"><span style=\"color: #424242; font-family: font-family: Open Sans, sans-serif;\">START YOUR DAY WITH NEW EXCITING BLENDS</span></p>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 61,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">We are hand picking world renowned beans monthly that will surly impress even the most discerning of coffee connoisseurs. Impress your family and guests with the best the world has to offer.</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 62,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "Email",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "TRY IT",
                        "icon": "",
                        "type": "form",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "transparent",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "value": "Submit",
                        "icon": "",
                        "type": "form",
                        "items": [
                            {
                                label: 'Email',
                                icon: null,
                                required: true,
                                text_color: '#FFFFFF',
                                join: true,
                                minLength: 5,
                                maxLength: 20,
                                type: 'email',
                                solo:true,
                                large: true,
                                model: "",
                                item: {
                                    "font-family": "Open Sans, sans-serif",
                                    label: 'Sign Up',

                                    icon: null,
                                    join: true,
                                    rounded: true,
                                    block: true,
                                    small: true,
                                    outlined: false,
                                    color:'#424242',
                                    text_color: "#FFFFFF",
                                    type: 'button',
                                    rules: []
                                },
                                rules: [
                                    v => !!v || 'E-mail is required',
                                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                                ]
                            },
                        ],
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false,
                        "solo": true,
                        "formType": "inline"
                    },
                ],
                "name": "6 Cols creative inline form and text",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 2,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "id": 63,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": '100%',
                        "min_height": 550,
                        "max_width": '100%',
                        "max_height": 350,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/templates/vegan/lyfe-fuel-C05u9Xh37to-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "contain": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                "name": "6 Cols creative Image Block",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 5,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 1,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": "/media/images/templates/vegan/lyfe-fuel-C05u9Xh37to-unsplash.jpg"
            }
        ],
        "container": false
    },
    {
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Left",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "container": true,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "name": "4 col, testimonial 1 image",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "id": 34,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 150,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/templates/vegan/lyfe-fuel-4wtxPhvQZds-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
            {
                "name": "4 col, testimonial 1 Text",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 8,
                "sm": 8,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">I bought all my climbing equipment at 80% off on black Friday. The savings was so amazing so I extended my vacation for a week!</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif\">Alex, Climbing Enthusiast</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 4,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#00E5FF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
        ],
        "pt": 12,
        "pb":12
    },
    {
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Right",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "container": true,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "name": "4 col, testimonial 1 Text",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 8,
                "sm": 8,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"color: #424242; font-family: ont-family: Open Sans, sans-serif;\">I bought all my climbing equipment at 80% off on black Friday. The savings was so amazing so I extended my vacation for a week!</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif\">Alex, Climbing Enthusiast</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 4,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#00E5FF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
            {
                "name": "4 col, testimonial 1 image",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "id": 34,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 150,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/templates/vegan/lyfe-fuel-w6cMF4idlS4-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
        ],
        "pt": 12,
        "pb":12
    },

    {
        "name": "Half Text Block",
        "justify": "start",
        "align": "center",
        "category": 1,
        "block_image": process.env.VUE_APP_MEDIA_URL +"images/blocks/fresttext.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": true,
        "background_image": "/media/preload/other/ante-hamersmit-SHqEPeNdOb0-unsplash.jpg",
        "height": 500,
        "inner_blocks": [
            {
                "id": 34,
                "block_contents": [
                    {
                        "id": 50,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "justify": "center",
                        "align": "start",
                        "color": "",
                        "content": "<p class=\"headline\"><span style=\"font-family: font-family: Open Sans, sans-serif;\">ETHICALLY SOURCED FROM EXOTIC PLACES</span></p>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 10,
                        "pr": 10,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 51,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif;\">We are hand picking world renowned beans monthly that will surly impress even the most discerning of coffee connoisseurs. impress your family and guests with the best the world has to offer.</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 10,
                        "pr": 10,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                ],
                "name": "Buyone 6 col block 6 offset rgba back",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 8,
                "xs": 12,
                "lg_offset": 6,
                "md_offset": 6,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#00000085",
                "stretch": true,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
        "order": 40
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Footer Block",
        "color": "#263238",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "id": 14,
                "block_contents": [{
                    "id": 42,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "<p class=\"body-1\"><span style=\"font-family: ont-family: Open Sans, sans-serif\">LeadSlide</span></p>",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Left",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }, {
                "id": 15,
                "block_contents": [{
                    "id": 44,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-facebook",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 45,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-twitter",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 46,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-linkedin",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Center",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "list",
                "background_image": ""
            }, {
                "id": 16,
                "block_contents": [{
                    "id": 43,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif">Copyright © LeadSlide</span></p>',
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Right",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
        "container": false
    }
]