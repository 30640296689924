import Blank from "./Blank"
import BuyonePage from "./BuyoneGetOne"
import Coupon from "./Coupon"
import Creative from "./Creative"
import Loyalty from "./Loyalty"
import Sports from "./Sports"
import Coffee from "./Coffee"
import Vegan from "./Vegan"
import Wts from "./Wts";
import BusinessSuccess from "./BusinessSuccess";

let defaultPages = [
    Blank,
    BuyonePage,
    Coupon,
    Creative,
    Loyalty,
    Sports,
    Coffee,
    Vegan,
    Wts,
    BusinessSuccess
];

export default defaultPages;
