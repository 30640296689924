export default {
    id: 1,
    name: 'Standard Email 1',
    container: true,
    container_width: 750,
    container_background_color: 'grey',
    background_color: 'grey',
    blocks: [
        {
            "name": 'Header',
            "description": 'Header Block',
            "category": 'outer',
            "block_image": "",
            "background": "black",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 2,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "center",
                    "block_contents": [
                        {
                            "type": "image",
                            "pt": 15,
                            "pl": 0,
                            "pr": 0,
                            "pb": 15,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": '/media/images/white-logo.png',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#FFFFFFF",
                            "href": "#",
                        }
                    ]
                },
            ]
        },
        {
            "name": 'Text Image',
            "description": 'Text and Image Blocks',
            "category": 'outer',
            "block_image": "",
            "background": "#f8f8f8",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 1,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "left",
                    "block_contents": [
                        {
                            "type": "text",
                            "pt": 15,
                            "pl": 0,
                            "pr": 0,
                            "pb": 15,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": 'Welcome To Leadslide',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#FFFFFFF",
                            "href": "#",
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "align": 'left',
                        },
                        {
                            "type": "text",
                            "pt": 15,
                            "pl": 0,
                            "pr": 0,
                            "pb": 15,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": 'Thank you for signing up to Leadslide.com. We\'re a ' +
                                'products company. The founders design software and work directly with the clients who use it.',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#FFFFFFF",
                            "href": "#",
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "align": 'left',
                        },
                    ]
                },
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 1,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "center",
                    "block_contents": [
                        {
                            "type": "image",
                            "pt": 15,
                            "pl": 0,
                            "pr": 0,
                            "pb": 15,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": '/images/hp-block4.png',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 350,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#FFFFFFF",
                            "href": "#",
                        }
                    ]
                },
            ]
        },
        {
            "name": 'Button Block',
            "description": 'Single Button Block',
            "category": 'outer',
            "block_image": "",
            "background": "#f8f8f8",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 2,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "center",
                    "block_contents": [
                        {
                            "type": "button",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 20,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": 'SIGN UP',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#FFFFFFF",
                            "color": "#FFFFFFF",
                            "background": "#FFFFFFF",
                            "href": "#",
                        }
                    ]
                },
            ]
        },
        {
            "name": 'Text Block',
            "description": 'Single Button Block',
            "category": 'outer',
            "block_image": "",
            "background": "#f8f8f8",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 2,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "left",
                    "block_contents": [
                        {
                            "type": "text",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 5,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus egestas ' +
                                'viverra. Sed non consequat nulla.',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#494949",
                            "href": "#",
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "align": 'left'
                        },
                        {
                            "type": "text",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 20,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus egestas ' +
                                'viverra. Sed non consequat nulla.',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#494949",
                            "href": "#",
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "align": 'left'
                        }
                    ]
                },
            ]
        },
        {
            "name": 'Video Block',
            "description": 'Single Button Block',
            "category": 'outer',
            "block_image": "",
            "background": "#f8f8f8",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 2,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "left",
                    "block_contents": [
                        {
                            "type": "video",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 20,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": 'https://www.youtube.com/watch?v=aCQEvqll-io',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 400,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#494949",
                            "href": "#",
                        }
                    ]
                },
            ]
        },
        {
            "name": 'Footer',
            "description": 'Footer Block',
            "category": 'outer',
            "block_image": "",
            "background": "black",
            "inner_blocks" : [
                {
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "span": 2,
                    "background": "transparent",
                    "custom_class": null,
                    "custom_styles": null,
                    "align": "center",
                    "block_contents": [
                    {
                            "type": "text",
                            "pt": 5,
                            "pl": 0,
                            "pr": 0,
                            "pb": 5,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "value": '© LeadSlide 2021',
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 150,
                            "max_height": 0,
                            "classes": "",
                            "text_color": "#ffffff",
                            "href": "#",
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "align": 'center'
                        }
                    ]
                },
            ]
        },
    ]
}