export default {
    id: 3,
    image: 'images/popups/blackfri.png',
    name: 'Black Friday',
    action_address: 'blank',
    location_address: 'blank',
    max_width: 600,
    min_height:100,
    action_type:1,
    type: 1,
    timing_type:1,
    using_image: false,
    color:'#000000',
    background_image: '',
    seconds:30,
    blocks: [
        {
            cols:12,
            max_width: 320,
            using_image: false,
            contents: [
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "justify": "center",
                    "align": "center",
                    "color": "#FFFFFFF",
                    "content": "BLACK FRIDAY",
                    "classes": "display-2",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "justify": "center",
                    "align": "center",
                    "color": "",
                    "content": "Black Friday sale, get huge discounts.",
                    "classes": "headline",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 6,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },

                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 5,
                    "pr": 5,
                    "pb": 5,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFFF",
                    "href": "#",
                    "value": "Submit",
                    "icon": "",
                    "type": "form",

                    "items": [
                        {
                            label: 'Email',
                            icon: null,
                            required: true,
                            solo: true,
                            dense: true,
                            minLength: 5,
                            maxLength: 20,
                            type: 'email',
                            join: true,
                            item: {
                                label: 'Sign Up',
                                color:'#cd7123',
                                text_color: '#FFFFFF',
                                icon: null,
                                rounded: false,
                                block: true,
                                outlined: false,
                                type: 'button',
                                rules: []
                            },
                            model: "",
                            rules: [
                                v => !!v || 'E-mail is required',
                                v => /.+@.+/.test(v) || 'E-mail must be valid',
                            ]
                        }
                    ],
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
            ]
        }
    ]
}
