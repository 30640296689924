export default [
    {
        "name": 'Header',
        "description": 'Header Block',
        "category": 'outer',
        "block_image": "",
        "filter": true,
        "background": "rgba(0,0,0, 1)",
        "background_image": '/media/images/unsorted/lyfe-fuel-V8RyrzwduR8-unsplash.jpg',
        "using_image": true,
        "inner_blocks" : [
            {
                "pt": 60,
                "pl": 0,
                "pr": 0,
                "pb": 60,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",

                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "image",
                        "pt": 15,
                        "pl": 15,
                        "pr": 15,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '/media/images/white-logo.png',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 350,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Text Image',
        "description": 'Text and Image Blocks',
        "category": 'outer',
        "block_image": "",
        "using_image": false,
        "background": "#f8f8f8",
        "background_image": null,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 30,
                "pr": 30,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 1,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "left",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="headline"><span style="font-family: font-family: Open Sans, sans-serif; color: #424242;">You\'re signed up!</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">We are excited to give you a chance for free shakes!</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">We are so confident that you will love our vegan shake that we like to do giveaways and let you try first.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },

                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242">If you can\'t wait tough we understand! So we will keep you on the giveaway list and you can try 25% off your first order.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },

                ]
            },
        ]
    },
    {
        "name": 'Button Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "using_image": false,
        "background": "#ffffff",
        "background_image": null,
        "pt":15,
        "pb": 15,
        "pl": 15,
        "pr": 15,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 0,
                "pr": 0,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 25,
                        "pl": 0,
                        "pr": 0,
                        "pb": 5,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "value": '<h1 class="text-h2 text-sm-h1" style="text-align: center;"><span style="font-weight: bold; font-family: font-family: Open Sans, sans-serif; color: #424242;">Coupon Code</span></h1>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'center'
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 20,
                        "value": '<h1 class="text-h2 text-sm-h1" style="text-align: center;"><span style="font-family: font-family: Open Sans, sans-serif; color: #424242; font-weight: bold;">SYB25</span></h1>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "display-3",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'center'
                    },
                    {
                        "type": "button",
                        "pt": 35,
                        "pl": 0,
                        "pr": 0,
                        "pb": 45,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "font-family": "Open Sans, sans-serif",
                        "value": 'SHOP NOW',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "large": true,
                        "block": false,
                        "rounded": false,
                        "background": "#424242",
                        color:'#424242',
                        text_color: "#FFFFFF",
                        "href": "#",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Text Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "background": "#f8f8f8",
        "using_image": false,
        "background_image": null,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 30,
                "pr": 30,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "left",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 25,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="headline"><span style="font-weight: bold; font-family: font-family: Open Sans, sans-serif; color: #424242;">25% Off all Products</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">We also offer other amazing products to help you get in great health. Check them out below.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">So we are offering the discount for all products!</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    }
                ]
            },
        ]
    },
    {
        "name": 'Button Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "background": "#ffffff",
        "background_image": null,
        "pt":0,
        "pb": 0,
        "using_image": false,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 0,
                "pr": 0,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "button",
                        "pt": 55,
                        "pl": 0,
                        "pr": 0,
                        "pb": 55,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": 'CHECK THEM OUT',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "href": "#",
                        "large": true,
                        "block": false,
                        "rounded": false,
                        "text_color": "#ffffff",
                        "color": "#67c6be",
                        "background": "#ffffff",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Text Block',
        "description": 'Ssb',
        "category": 'outer',
        "block_image": "",
        "background": "#f8f8f8",
        "using_image": false,
        "background_image": null,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 30,
                "pr": 30,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "left",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 25,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-2" style="text-align: center;"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242; font-weight: bold;">Send to support@leadslide.com</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-2" style="text-align: center; text-decoration: underline;"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">[[UNSUB_LINK]]</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-2" style="text-align: center;"><span style="font-family: ont-family: Open Sans, sans-serif; color: #424242;">Leadslide Limited - 123 Street - Town, State ZIP - USA</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    }
                ]
            },
        ]
    },
]