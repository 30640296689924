<template>
  <v-card color="basil" flat>
    <v-card min-height="425" flat class="elevation-0">
      <v-card-title v-show="show_title" class="text-center justify-center py-6">
        <h1 class="font-weight-bold display-3 basil--text">
          Image Gallery
        </h1>
      </v-card-title>
      <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
      >
        <v-tab
            v-for="(t, index) in defaultGalleries"
            :key="index"
        >
          {{ t.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-if="not_loading"
            v-for="(t, index) in defaultGalleries"
            :key="index"
        >
          <v-card flat color="basil">
            <v-row>
              <v-col cols="12">
                <v-card min-height="100" flat>
                  <v-container fluid>
                    <v-row>

                      <v-col v-if="t.id === 0" cols="12">
                        <v-file-input accept="image/*"
                                      v-model="file"
                                      label="Select Image..."></v-file-input>

                      </v-col>
                      <v-col
                          v-if="t.id > 0"
                          v-for="(image, index) in gallery_images"
                          :key="index"
                          class="d-flex child-flex"
                          cols="3"
                      >

                        <v-card
                            @click="!change_value ? item[changeKey] = image.image: item.value = image.image"
                            :class="{'success pa-1':item[changeKey] === image.image || item.value === image.image }"
                            flat tile class="d-flex">
                          <v-img
                              :gradient="overlay === true ? 'to top right, '+overlay_color+', ' + overlay_color: ''"
                              :src="get_thumbnail(image)"

                              aspect-ratio="1"
                              class="grey lighten-2"

                          >
                            <template v-slot:placeholder>
                              <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                              >
                                <v-progress-circular indeterminate
                                                     color="grey lighten-5">

                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                    <user-gallery v-if="t.id === 0"
                                  :item="item"
                                  :change_value="change_value"
                                  :changeKey="changeKey"
                                  :search_key="user_image_search_key"
                    :return_click="set_user_image"
                    ></user-gallery>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-card>
    <v-divider class="mt-4 mb-6"></v-divider>
    <v-row v-if="tab !== 8">
      <v-col cols="12">
        <global-functions-pagination
            :watch_search="true"
            :pagination="pagination"
            :return_results="setImages">

        </global-functions-pagination>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GlobalFunctionsPagination from '@/components/Global/Functions/Pagination.vue';
import UserGallery from "./UserGallery.vue";
export default {
  components: {UserGallery, GlobalFunctionsPagination},
  computed: {
    ...mapGetters([
      'defaultGalleries',
      'userGalleries',
      'gallery_images',
      'isLoading'
    ]),
    ...mapActions([
        'loadDefaultGalleries'
    ])
  },
  props: {
      changeKey: {
        type: [String],
        required: false,
        default: 'background_image'
      },
    change_value: Boolean,
    item: {
      type: [Object],
      required: true,
      default: {

      }
    },
    show_title: {
      type: [Boolean],
      default: true,
      required: false
    },
    overlay: {
      type: [Boolean],
      required: false,
      default: false
    },
    overlay_color: {
      type: [String],
      required: false,
      default: 'rgba(0, 0, 0, 0.5)'
    }
  },
  data() {
    return {
      user_image_search_key: "",
      tab: 0,
      page: 0,
      limit: 20,
      offset: 0,
      file: null,
      imageUrl: null,
      not_loading: true,
      baseUrl: process.env.VUE_APP_MEDIA_URL,
      pagination: {
        url: '/basic/default-images/',
        search: {gallery:1},
        limit: 8,
        page: 1
      },
      tabs: [
        {
          id: 0,
          name: 'My Images',
          base_link: 'http://127.0.0.1:8000/',
          images: []
        },
      ],
    }
  },
  watch: {
    'tab': function(nv) {
      let gallery = this.defaultGalleries[nv];
      if(typeof gallery !== "undefined")
      {
        this.pagination.search.gallery = gallery.id;
      }
    },
    'file': function (nv) {
      if (nv && nv !== '') {
        this.upload_image(nv);
      }
    },
  },
  mounted() {
    this.not_loading = false;
    this.$store.dispatch('loadDefaultGalleries');

    setTimeout(() => {
      this.not_loading = true;
    }, 1200);
  },
  methods: {
    get_thumbnail(image_obj) {
      if(image_obj.thumbnail) {
        return image_obj.thumbnail;
      }

      return image_obj.image;
    },
    set_user_image(value) {
      console.log(value);
      if(!this.change_value)
      {
        this.item[this.changeKey] = value;
      }
      else
      {
        this.item.value = value;
      }
    },
    setImages(results) {
      this.$store.dispatch('setGalleryImages', results);
    },
    upload_image(file) {
      const formData = new FormData();
      formData.append("image", file);
      this.$hpost('/basic/images/', formData).then((response) => {
        if (typeof response.data !== 'undefined') {

          this.user_image_search_key = new Date().getTime();
          this.file = null;
          this.$notify({
            title: 'success:',
            text: 'Successfully uploaded image',
            group: 'notifications',
            position: 'top right'
          });
        }
      });
    },
  }
}
</script>
