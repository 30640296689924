const state = {
    loader: {
        loading: false,
        message: ""
    }
}

const actions = {
    setLoading: (store, payload) => {
      store.commit('mutateLoading', payload)
    },
}

const getters = {
    loader: state => {
        return state.loader;
    }
}

const mutations = {
    mutateLoading: (state, payload) => {
        state.loader.loading = payload.loading;
        state.loader.message = payload.message;
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}

