<template>
  <v-app light v-cloak>
    <v-main>
      <div id="core-view">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
        <notifications group="notifications" :position="'top right'"></notifications>

        <!-- Global Modals -->
        <Loader />
        <OuterBlock />
        <IconBlock />
        <ImageBlock />
        <FormBlock />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Loader from './components/Global/Modals/Loader.vue';
import OuterBlock from './components/Global/Modals/OuterBlock.vue';
import IconBlock from './components/Global/Modals/IconBlock.vue';
import ImageBlock from './components/Global/Modals/ImageBlock.vue';
import FormBlock from './components/Global/Modals/FormBlock.vue';
export default {
  name: 'App',

  components: {
    Loader,
    OuterBlock,
    IconBlock,
    ImageBlock,
    FormBlock,
  },

  data: () => ({
    //
  }),
  mounted () {

  },
  beforeDestroy () {
  },
  methods: {
    onResponsiveInverted () {
      this.$store.dispatch('setResponsive', window.innerWidth);
    }
  }
};
</script>
