<template>
  <v-row>
    <v-col
        v-if="images.length"
        v-for="(image, index) in images"
        :key="index"
        class="d-flex child-flex"
        cols="3"
    >
      <v-card
          @click="return_click(image.image)"
          :class="{'success pa-1':item[changeKey] === image.image || item.value === image.image }"
          flat tile class="d-flex"
      >
        <v-img
            :src="get_thumbnail(image)"
            :lazy-src="get_thumbnail(image)"
            aspect-ratio="1"
            class="grey lighten-2"

        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular indeterminate
                                   color="grey lighten-5">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-col>

    <v-col cols="12">
      <global-functions-pagination
          :watch_search="true"
          :pagination="pagination"
          :return_results="set_images">

      </global-functions-pagination>
    </v-col>
  </v-row>
</template>

<script>
import GlobalFunctionsPagination from '@/components/Global/Functions/Pagination.vue';

export default {
  name: "UserGallery",
  props: {
    search_key: {
      type: String,
      default: 'time'
    },
    return_click: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object
    },
    changeKey: {
      type: [String],
      required: false,
      default: 'background_image'
    },
    change_value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GlobalFunctionsPagination
  },
  data() {
    return {
      images: [],
      baseUrl: process.env.VUE_APP_MEDIA_URL,
      pagination: {
        url: '/basic/images/',
        search: {time: ""},
        limit: 8,
        page: 1
      },
    }
  },
  watch: {
    search_key: {
      handler: function (newVal, oldVal) {
        this.pagination.search.time = newVal;
        this.pagination.page = 1;
      },
      deep: true
    }
  },
  methods: {
    get_thumbnail(image_obj) {
      if(image_obj.thumbnail) {
        return image_obj.thumbnail;
      }

      return image_obj.image;
    },
    set_images(images) {
      this.images = images;
    }
  }
}
</script>