export default {
    id: 2,
    image: 'images/popups/2.png',
    name: 'VIP Club',
    action_address: 'blank',
    location_address: 'blank',
    max_width: 600,
    min_height:100,
    action_type:1,
    type: 1,
    timing_type:1,
    using_image: true,
    color:'#c9c9c9',
    background_image: '/api-lcmedia/preload/outdoor/simon-berger-twukN12EN7c-unsplash.jpg',
    seconds:30,
    blocks: [
        {
            cols:12,
            max_width: 320,
            using_image: false,
            contents: [
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "justify": "center",
                    "align": "center",
                    "color": "#FFFFFFF",
                    "content": "VIP CLUB",
                    "classes": "display-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "justify": "center",
                    "align": "center",
                    "color": "",
                    "content": "Get Free Stuff",
                    "classes": "headline",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },

                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 5,
                    "pr": 5,
                    "pb": 5,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFFF",
                    "href": "#",
                    "value": "Submit",
                    "icon": "",
                    "type": "form",
                    "items": [
                        {
                            label: 'Name',
                            icon: null,
                            required: true,
                            minLength: 5,
                            maxLength: 20,
                            type: 'text',
                            solo: true,
                            model: "",
                            rules: [
                                v => !!v || 'Name is required',
                                v => v.length <= 10 || 'Name must be less than 10 characters',
                            ]
                        },
                        {
                            label: 'Email',
                            icon: null,
                            required: true,
                            solo: true,
                            minLength: 5,
                            maxLength: 20,
                            type: 'email',

                            model: "",
                            rules: [
                                v => !!v || 'E-mail is required',
                                v => /.+@.+/.test(v) || 'E-mail must be valid',
                            ]
                        },
                        {
                            label: 'Sign Up',
                            color:'#A42A86',
                            text_color: '#FFFFFF',
                            icon: null,
                            rounded: false,
                            block: true,
                            outlined: false,
                            type: 'button',
                            rules: []
                        },
                    ],
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
            ]
        }
    ]
}
