import TinyMceConfig from "../LeadSlide/Blocks/TinyMceConfig";

const state = {
    tinyMCE: {
        config: TinyMceConfig
    },
    ckeditor: {
        config: null
    }
}

const getters = {
    tinyConfig: state => {
        return state.tinyMCE.config;
    },
    ckConfig: state => {
        return state.ckeditor.config;
    }
}

const actions = {

}

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations
}