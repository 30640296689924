import axios from 'axios'

const state = {
    default_galleries: [],
    user_galleries: [],
    loading: false,
    gallery_images: []
}

const actions = {
    loadDefaultGalleries: store => {
        if(!store.state.default_galleries.length)
        {
            axios.get(
                '/basic/default-gallery/'
            ).then(function(response) {
                console.log(response.data)
                let galleries = response.data.results;
                store.commit('setDefaultGalleries', galleries)
            });
        }
    },
    loadUserGalleries: (store) => {

    },
    loadDefaultImages: (store, payload) => {
        let gallery = store.state.default_galleries[payload.index];

        if(gallery)
        {
            if(!payload.image_count)
            {
                store.state.loading = true;
                let load_url = '/basic/default-images/?gallery='+gallery.id+'&limit=8';
                if(payload.next)
                {
                    load_url = payload.next;
                }
                axios.get(
                    load_url
                ).then(function(response) {
                    gallery.next = response.data.next;
                    gallery.previous = response.data.previous;
                    gallery.count = response.data.count;
                    gallery.images = response.data.results;
                    store.state.loading = false;
                });
            }
        }

    },
    loadUserImages: (store, payload) => {
        let gallery = state.default_galleries.find(o => o.id === 0);
        store.state.loading = true;
        axios.get(
            '/basic/images/'
        ).then(function(response) {
            if(gallery)
            {
                gallery.next = response.data.next;
                gallery.previous = response.data.previous;
                gallery.count = response.data.count;
                gallery.images = response.data.results;
                store.state.loading = false;
            }
            console.log(response);
            console.log('User Images Loaded');
        }).catch((e) => {
            console.log('User Images Failed To Load..');
            console.log(e);
        }).finally(() => {
            store.state.loading = false;
        });
    },
    setGalleryImages: (store, payload) => {
        store.commit('mutateGalleryImages', payload);
    },
}

const getters = {
    defaultGalleries: state => {
        return state.default_galleries
    },
    userGalleries: state => {
        return state.user_galleries
    },
    isLoading: state => {
        return state.loading
    },
    gallery_images: state => {
        return state.gallery_images;
    }
}

const mutations =  {
    mutateGalleryImages: (state, payload) => {
        state.gallery_images = payload;
    },
    setUserGalleries: (state, payload) => {
        state.user_galleries = payload;
    },
    setDefaultGalleries: (state, payload) => {
        state.default_galleries = payload;
        let custom_gallery =    {
            id: 0,
            name: 'My Images',
            base_link: '/media/',
            images: []
        };
        state.default_galleries.push(custom_gallery);
    },
    setDefaultImages: (state, payload) => {
        state.default_galleries[payload.index].next = payload.next;
        state.default_galleries[payload.index].previous = payload.previous;
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}