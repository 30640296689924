import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":800},model:{value:(_vm.imageBlock.active),callback:function ($$v) {_vm.$set(_vm.imageBlock, "active", $$v)},expression:"imageBlock.active"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSwitch,{attrs:{"label":"Hide on mobile"},model:{value:(_vm.imageBlock.item.hide_on_mobile),callback:function ($$v) {_vm.$set(_vm.imageBlock.item, "hide_on_mobile", $$v)},expression:"imageBlock.item.hide_on_mobile"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c('blocks-functions-gallery',{attrs:{"change_value":true,"item":_vm.imageBlock.item}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue-grey darken-4","text":""},on:{"click":_vm.close_dialog}},[_vm._v(" Close ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }