<template>
  <div class="color_picker_options" :class="inline_modal === true ? 'inline_mode': 'popup_mode'">

    <div class="color_wrapper" :class="inline_modal === true ? 'inline_wrapper': 'popup_wrapper'">
      <div class="float-left text-left">
        <v-card
            @click="modal.active = !modal.active"
            :class="rounded === true ? 'radius--100': 'no-radius'"
            height="50" width="50"
            :color="color">

        </v-card>

      </div>
      <div class="float-left ml-2">
        <p v-show="!show_text_options" class="body-1 font-weight-bold mt-2">
          {{ label }}
        </p>

        <v-text-field v-show="show_text_options" :label="label" v-model="color"></v-text-field>
      </div>
      <div class="clear-fix"></div>
      <div v-show="modal.active === true" class="side_modal" :class="inline_modal === true ? 'inline_mode': 'popup_mode'">
        <v-card color="blue-grey darken-4" class="inner">
          <v-color-picker
              dark
              flat
              v-model="color"
              :hide-canvas="false"
              :hide-inputs="true"
              :hide-mode-switch="true"
              :canvas-height="140"
              :show-swatches="false"
              class="mx-auto"
              :class="rounded === true ? 'radius--100': 'no-radius'"
          ></v-color-picker>
          <v-card-actions>
            <v-btn
                outlined
                block
                  color="white"
                  @click="modal.active = false"
                >
              Okay
                </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: [String],
      default: "Color",
      required: false,
    },
    rounded: {
      type: [Boolean],
      default: false,
      required: false
    },
    show_text_options: {
      type: [Boolean],
      default: true,
      required: false
    },
    inline_modal: {
      type: [Boolean],
      default: true,
      required: false
    },
    color_change: {
      type: [Function],
      required: true
    },
    types: {
      type: [Array],
      required: false
    },
    init_color: {
      type: [String],
      required: true
    },
  },
  components: {

  },
  created() {
    this.color = this.init_color;
  },
  watch: {
    'color': function(color) {
      this.color_change(color);
    }
  },
  data() {
    return {
      modal: {
        active: false
      },
      color: "#696969"
    }
  }
}
</script>

<style lang="scss">
  .color_picker_options {
    .inline_wrapper {
      position: relative;
    }
    .color_wrapper {

      .side_modal {

      }

      .popup_mode {
        background: rgba(0,0,0, 0.5);
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
        margin: auto;

        .inner {
          position: absolute;
          width: 250px;
          height:250px;
          top:0;
          bottom:0;
          right:0;
          left:0;
          margin: auto;
        }
      }

      .side_modal.inline_mode {
        max-width: 250px;
        .inner {
          position: absolute;
          left:50px;
          top:0;
          z-index:4;
        }
      }

      .radius--100 {
        border-radius: 150px;
      }
      .no-radius {
        border-radius: 0;
      }

      .clear-fix {
        clear: both;
      }
    }
  }
</style>