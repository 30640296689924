export default [
    {
        "category": 1,
        "block_image": "",
        "name": "Banner Block",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "/media/images/templates/coffee/marc-buddha-beans-coffee-co-xZZbJo3XABA-unsplash.jpg",
        "height": 850,
        "inner_blocks": [
            {
            "block_contents": [
                {
                    "id": 55,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "shadow": false,
                    "content": "<h1 style=\"text-align:center; font-weight: bold;\" class=\"text-h2 text-sm-h1\"><span style=\"color: #FFFFFF; font-family: : Open Sans, sans-serif\">25% OFF COFFEE</span></h1><h1 style=\"text-align:center; font-weight: bold;\" class=\"text-h2 text-sm-h1\"><span style=\"color: #FFFFFF; font-family: : Open Sans, sans-serif\">SUBSCRIPTIONS</span></h1>",
                    "classes": "",
                    "pt": 12,
                    "pl": 0,
                    "pr": 0,
                    "pb": 5,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 56,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "<h4 style=\"text-align:center;\" class=\"text-h4\"><span style=\"color: #FFFFFF; font-family: : Open Sans, sans-serif\">Offer applies to first order only.</span></h4>",
                    "classes": "body-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFF",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "id": 57,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 12,
                    "mt": 5,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "href": "#",
                    "color": "#7cc576",
                    "font-family": "font-family: Open Sans, sans-serif",
                    "content": "SIGN UP",
                    "text_color": "#FFFFFF",
                    "value": "SIGN UP",
                    "icon": "",
                    "type": "button",
                    "xLarge": true,
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }
            ],
            "name": "Creative Banner Block",
            "block_image": "",
            "pt": 12,
            "pl": 8,
            "pr": 8,
            "pb": 12,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }
        ],
        "container": false,
        image_overlay: true,
        image_overlay_color: "rgba(0,0,0,0.2)"
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Text Banner",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<h4 class=\"display-1\" style=\"text-align:center; font-weight: bold;\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">COFFEE SUBSCRIPTIONS</span></h4>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align:center;\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif;\">Sign up to our monthly coffee service and grt new monthly blends from aound the globe</span></p>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 7,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                ],
                "name": "12 Cols banner button block",
                "block_image": "",
                "pt": 12,
                "pl": 8,
                "pr": 8,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
        "container": true,
        "pt": 12,
        "pb": 12,
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Text Icon Block",
        "color": "white",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 17,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 6,
                        "pr": 6,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 20,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center; font-weight: bold;\" class=\"headline\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">SIGN UP AND GET YOUR DISCOUNTED ORDER</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-1\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">Get your first order 25% off and get monthly coffees sent to your door.</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "id": 18,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-close",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center; font-weight: bold;\" class=\"headline\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">CANCEL ANY TIME & NO HASTLE REFUNDS</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-1\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">Cancel your subscriptions anytime and keep your first bag free if unsatisfied!</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "id": 4,
                "block_contents": [
                    {
                        "id": 19,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "display-3",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-airplane",
                        "type": "icon",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 90,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center; font-weight: bold;\" class=\"headline\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">FAST AND FREE SHIPPING</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 22,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p style=\"text-align: center;\" class=\"body-1\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">Subscriptions also come with free shipping and are aimed at coming at the start of each month.</span></p>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 Col Block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true,
        "pt": 1,
        "pb": 12,
        "mb": 12
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Inline Form Image",
        "color": "#f8f8f8",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 60,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "<h4 class=\"display-1\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">START YOUR DAY WITH NEW EXCITING BLENDS</span></h4>",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 12,
                        "pr": 12,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 61,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "<p class='body-1'><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">We are hand picking world renowned beans monthly that will surly impress even the most discerning of coffee connoisseurs. Impress your family and guests with the best the world has to offer.</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 12,
                        "pr": 12,
                        "pb": 0,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "transparent",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 12,
                        "pr": 12,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "value": "Submit",
                        "icon": "",
                        "type": "form",
                        "items": [
                            {
                                label: 'Email',
                                icon: null,
                                required: true,
                                text_color: '#FFFFFF',
                                minLength: 5,
                                maxLength: 20,
                                type: 'email',
                                solo:false,
                                outlined: true,
                                rounded: true,
                                large: false,
                                dense: false,
                                cols: 8,
                                model: "",
                                pl:3,
                                pr:0,
                                pt:3,
                                pb:3,
                                rules: [
                                ]
                            },
                            {
                                cols: 4,
                                pl:4,
                                pr:3,
                                pt:3,
                                pb:3,
                                label: "Button",
                                select_label: "Button",
                                select_icon: "mdi-button-cursor",
                                icon: null,
                                minLength: 0,
                                maxLength: 0,
                                required: false,
                                rounded: false,
                                xLarge: true,
                                large: false,
                                small: false,
                                outlined: false,
                                dense: false,
                                solo: false,
                                borderRadius: 4,
                                color: "#7cc576",
                                text_color: "#ffffff",
                                block: true,
                                box_shadow: false,
                                type: "button",
                                rules: []
                            }
                        ],
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false,
                        "solo": true,
                        "formType": "inline"
                    },
                ],
                "name": "6 Cols creative inline form and text",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 2,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "id": 63,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "transparent",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": '100%',
                        "min_height": 450,
                        "max_width": '100%',
                        "max_height": '100%',
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/templates/coffee/angelina-yan-xRFffIGVQAk-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "6 Cols creative Image Block",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 5,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 1,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
        "mt":12,
        "mb": 12
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Left",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "container": true,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",

        "inner_blocks": [
            {
                "name": "4 col, testimonial 1 image",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 5,
                "sm": 5,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "id": 34,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 200,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/unsorted/andriyko-podilnyk-XWQHwxXwo_Q-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
            {
                "name": "4 col, testimonial 1 Text",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align: center;\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">These blends have been amazing. Every month i feel like a kid again and its Christmas morning! My friends and family rave about he exotic coffees.</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align: center;\"><span style=\"color: #5fedf7; font-family: : Open Sans, sans-serif;\"><strong>Luke</strong>, <i>Coffee Enthusiast</i></span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 4,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#00E5FF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
        ],
        "pt":14,
        "pb": 12
    },
    {
        "category": 1,
        "block_image": "",
        "name": "Testimonial Block Right",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "container": true,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "name": "4 col, testimonial 1 Text",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 4,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align: center;\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif\">I always liked coffee but now with these monthly treats it has taken my enjoyment to a new level!. Great product.</span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<p class=\"body-1\" style=\"text-align: center;\"><span style=\"color: #5fedf7; font-family: : Open Sans, sans-serif\"><strong>Mandy</strong>, <i>Coffee Lover</i></span></p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 4,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#00E5FF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
            {
                "name": "4 col, testimonial 1 image",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 5,
                "sm": 5,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": "",
                "block_contents": [
                    {
                        "id": 34,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 200,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "/media/images/unsorted/gian-cescon-GxQ13MXLTHQ-unsplash.jpg",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ]
            },
        ],
        "pt":12,
        "pb": 14,
        "mb": 12
    },

    {
        "category": 1,
        "block_image": "",
        "name": "Text Button Banner",
        "color": "#f8f8f8",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "align": "center",
        "justify": "center",
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "<h4 class=\"display-1\" style=\"text-align:center;\"><span style=\"color: #424242; font-family: : Open Sans, sans-serif; font-size:28pt;\">YOU DESERVE THE BEST COFFEE, TRY RISK FREE</span></h4>",
                        "classes": "display-1",
                        "pt": 12,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#7cc576",
                        "content": "Learn More",
                        "classes": "",
                        "pt": 6,
                        "pl": 0,
                        "pr": 0,
                        "pb": 12,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "font-family": "font-family: Open Sans, sans-serif",
                        "value": "GET IT",
                        "icon": "",
                        "type": "button",
                        "xLarge": true,
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "12 Cols banner button block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
        "container": true,
        "mt": 12,
        "pt": 14,
        "pb": 14
    },
    {
        "category": 1,
        "block_image": "",
        "name": "Footer Block",
        "color": "#263238",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "id": 14,
                "block_contents": [{
                    "id": 42,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "<p class=\"body-1\"><span style=\"font-family: : Open Sans, sans-serif; \">LeadSlide</span></p>",
                    "classes": "small",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Left",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }, {
                "id": 15,
                "block_contents": [{
                    "id": 44,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-facebook",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 45,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-twitter",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 46,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-linkedin",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Center",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "list",
                "background_image": ""
            }, {
                "id": 16,
                "block_contents": [{
                    "id": 43,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "<p class=\"body-1\"><span style=\"font-family: : Open Sans, sans-serif; \">Copyright © LeadSlide</span></p>",
                    "classes": "small",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Right",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
        "container": false
    }
]