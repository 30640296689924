import EXP_BLOCKS from '../Popups/Blocks/Exclusive'
import CCE_BLOCKS from '../Emails/Blocks/Coffee'

export default {
    user: null,
    title: 'Signup Exclusive Offer',
    name: "Signup Exclusive",
    paragraphs: [
        '2 step funnel for getting signups for a discount campaign. Offer a discount for a customers first order.',
        'This funnel has a popup and email with a coupon code.',
    ],
    state: 1,
    type: 7,
    category: 2,
    icon: 'mdi-coffee',
    active: false,
    steps: [
        {
            order: 1,
            type: 2,
            name: "suo",
            color: "#ffffff",
            using_image: false,
            background_image: null,
            timing_type: 1,
            seconds: 10,
            image: '/media/images/popups/exclusive.png',
            blocks: EXP_BLOCKS,
        },
        {
            order: 2,
            type: 3,
            container_background_color: '#cccccc',
            container_width: 650,
            container: true,
            image: '/media/images/emails/1.png',
            blocks: CCE_BLOCKS,
        }
    ],
    gallery: [
        {
            funnel_step: 1,
            image: '/media/images/popups/exclusive.png',
            thumbnails: []
        }
    ],
}