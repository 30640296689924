import axios from 'axios';

const apiRequest = async (method, url, payload = null, customHeaders = {}) => {
    let response_data = {
        success: false,
        message: '',
        data: undefined,
    };

    try {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('access_token') ? `jwt ${localStorage.getItem('access_token')}` : undefined,
            ...customHeaders,
        };

        let response;
        if (method === 'GET' || method === 'DELETE') {
            response = await axios({
                method,
                url,
                headers,
            });
        } else {
            response = await axios({
                method,
                url,
                data: payload,
                headers,
            });
        }

        response_data.success = true;
        response_data.data = response.data;
    } catch (error) {
        response_data.message = error.response?.data?.message || error.response?.data || 'An error occurred';
    }

    return response_data;
};

export default apiRequest;
