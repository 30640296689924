let OuterBlocks = [
    // form block
    {
        name: 'Subscription Form',
        description: 'Form Block',
        category: 'outer',
        child_category: 'nav',
        "state": 1,
        "image": "/media/images/blocks/formtext2.png",
        "block_image": "media/images/blocks/formtext2.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": true,
        "background_image": "/media/preload/corporate/photo-1432821596592-e2c18b78144f.jpg",
        "height": 0,
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 84,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "<h4 class='display-1'>Leadslide</h4>",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#ffffffff",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 85,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "#ffffff",
                        "content": "<p class='body-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel lacus tincidunt, porta nulla vitae, sodales ligula. Phasellus volutpat odio vitae venenatis venenatis. Proin eu condimentum velit. Sed ultrices tincidunt aliquam. Duis ipsum massa, hendrerit quis quam eget, laoreet porta lectus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla tincidunt condimentum massa, imperdiet vulputate turpis tempor et.</p>",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 4,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#ffffffff",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "Title Text",
                "block_image": "",
                "pt": 0,
                "pl": 4,
                "pr": 4,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "transparent",
                        "content": "",
                        "classes": "",
                        "pt": 12,
                        "pl": 12,
                        "pr": 12,
                        "pb": 12,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 12,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "value": "Submit",
                        "icon": "",
                        "type": "form",
                        "items": [
                            {
                                label: 'Name',
                                icon: null,
                                required: true,
                                minLength: 5,
                                maxLength: 20,
                                type: 'text',
                                model: "",
                                rules: [
                                    v => !!v || 'Name is required',
                                    v => v.length <= 10 || 'Name must be less than 10 characters',
                                ]
                            },
                            {
                                label: 'Email',
                                icon: null,
                                required: true,
                                minLength: 5,
                                maxLength: 20,
                                type: 'email',
                                model: "",
                                rules: [
                                    v => !!v || 'E-mail is required',
                                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                                ]
                            },
                        ],
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#6FF393FF",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 6,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "JOIN NOW",
                        "icon": "mdi-bookmark-multiple",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": true
                    }
                ],
                "name": "12 col",
                "block_image": "",
                "pt": 0,
                "pl": 4,
                "pr": 4,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#FFFFFFC5",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true,
    },

    // Navigation Blocks
    {
        name: 'Navigation',
        description: 'Navigation Block',
        category: 'outer',
        child_category: 'nav',
        "state": 1,
        "image": "/media/images/blocks/footer2.png",
        "block_image": "media/images/blocks/footer2.png",
        "color": "transparent",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "transparent",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "value": "Title",
                        "icon": "",
                        "type": "navigation",
                        "items": [
                            {
                                text: 'Home',
                                icon: '',
                                href: '#'
                            },
                            {
                                text: 'About',
                                icon: '',
                                href: '#'
                            },
                            {
                                text: 'Contact',
                                icon: '',
                                href: '#'
                            }
                        ],
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": true,
                        "show_popup": false,
                        "block": false
                    },
                ],
                "name": "12 col",
                "block_image": "",
                "pt": 0,
                "pl": 4,
                "pr": 4,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
    },

    // Banner Blocks
    {
        name: 'Banner',
        description: 'banner ideal for home pages',
        category: 'outer',
        child_category: 'banner',
        "state": 1,
        "image": "/media/images/pages/3.png",
        "id": 12,
        "block_image": "media/images/blocks/banner.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "/media/images/gallery/creative-banner.jpg",
        "height": 550,
        "inner_blocks": [{
            "id": 20,
            "block_contents": [{
                "id": 55,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "CREATIVE DIGITAL SOLUTIONS",
                "classes": "display-4",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "BUY 1 GET 1 FREE",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": true,
                "show_popup": false,
                "block": false
            }, {
                "id": 56,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur!",
                "classes": "display-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": true,
                "show_popup": false,
                "block": false
            }, {
                "id": 57,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#69F0AE",
                "content": "LEARN MORE",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "Sign Up",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Creative Banner Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": false,
    },

    // text icon blocks
    {
        "category": 1,
        "block_image": "media/images/blocks/iconcontent.png",
        "name": "Text Icon Block",
        "color": "white",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 2,
            "block_contents": [
                {
                    "id": 17,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "",
                    "classes": "display-3",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-truck-fast",
                    "type": "icon",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 20,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "Fast and Free Shipping",
                    "classes": "display-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 22,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                    "classes": "body-2",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 3,
            "block_contents": [{
                "id": 18,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-bookmark-multiple",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 21,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Up to 80% Selected Merchandise",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 4,
            "block_contents": [{
                "id": 19,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-refresh",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 90,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Easy FREE 30 Day Returns",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    },

    //text image flat
    {
        "name": "Half Text Block",
        "justify": "start",
        "align": "center",
        "category": 1,
        "block_image": "media/images/blocks/imgtxt.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image":  "/media/preload/sports/photo-1420393000485-4697383da9ec.jpg",
        "height": 500,
        "inner_blocks": [
            {
                "id": 34,
                "block_contents": [
                    {
                        "id": 50,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "justify": "center",
                        "align": "center",
                        "color": "",
                        "content": "LOYALTY PROGRAM",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#6e6e6e",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 51,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Join the loyalty program and collect points the more you buy. We will notify you by email when we have discounts as well as sending you special coupons when you sign up. Free to try and you can unsubscribe anytime.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#6e6e6e",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 52,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#6e6e6e",
                        "content": "FREE SIGN UP",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "FREE SIGN UP",
                        "icon": "",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": true,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "Buyone 6 col block 6 offset rgba back",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 8,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#ffffff",
                "stretch": true,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
        "order": 40
    },

    // Text forrest image
    {
        "name": "Half Text Block",
        "justify": "start",
        "align": "center",
        "category": 1,
        "block_image": "media/images/blocks/fresttext.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": true,
        "background_image":  "/media/preload/other/photo-1448375240586-882707db888b.jpeg",
        "height": 500,
        "inner_blocks": [
            {
                "id": 34,
                "block_contents": [
                    {
                        "id": 50,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "justify": "center",
                        "align": "center",
                        "color": "",
                        "content": "LOYALTY PROGRAM",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 51,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Join the loyalty program and collect points the more you buy. We will notify you by email when we have discounts as well as sending you special coupons when you sign up. Free to try and you can unsubscribe anytime.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 52,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "FREE SIGN UP",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "FREE SIGN UP",
                        "icon": "",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": true,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "Buyone 6 col block 6 offset rgba back",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 8,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#00000085",
                "stretch": true,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
        "order": 40
    },

    // Text hiking image
    {
        "name": "Half Text Block",
        "justify": "end",
        "align": "center",
        "category": 1,
        "block_image": "media/images/blocks/hiking.png",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": true,
        "background_image":  "/media/preload/sports/photo-1420393000485-4697383da9ec.jpg",
        "height": 500,
        "inner_blocks": [
            {
                "id": 34,
                "block_contents": [
                    {
                        "id": 50,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "justify": "center",
                        "align": "center",
                        "color": "",
                        "content": "LOYALTY PROGRAM",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 51,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Join the loyalty program and collect points the more you buy. We will notify you by email when we have discounts as well as sending you special coupons when you sign up. Free to try and you can unsubscribe anytime.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFF",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 52,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "FREE SIGN UP",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "FREE SIGN UP",
                        "icon": "",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": true,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "Buyone 6 col block 6 offset rgba back",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 8,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#00000085",
                "stretch": true,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "height: 100%; ",
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false,
        "order": 40
    },

    // text image blocks
    {
        "category": 1,
        "block_image": "media/images/blocks/contentboximage.png",
        "name": "Half Text, Half Video Block",
        "color": "#E0E0E0",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "id": 32,
                "block_contents": [
                    {
                        "id": 84,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "AMAZING SPORTS SHOES",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 85,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Buy one get one on these amazing shoes. These are the newest shoes we have that actually help you lose weight and motivate you to move your body. The results and research of these are amazing and we have attached our research for you to see the results.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }, {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "light-blue lighten-1",
                        "content": "",
                        "classes": "white--text",
                        "pt": 8,
                        "pl": 0,
                        "pr": 0,
                        "pb": 8,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "",
                        "href": "#",
                        "value": "Add To Cart",
                        "icon": "mdi-cart",
                        "type": "button",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }],
                "name": "Buyone Title, text button 6 col",
                "block_image": "",
                "pt": 8,
                "pl": 0,
                "pr": 0,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "primary",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 200,
                    "min_height": 280,
                    "max_width": 400,
                    "max_height": 280,
                    "text_color": "white--text",
                    "href": "#",
                    "value": "images/unsorted/shoescut.png",
                    "icon": "",
                    "type": "image",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }
                ],
                "name": "Buyone image 6 col",
                "block_image": "",
                "pt": 8,
                "pl": 0,
                "pr": 0,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true
    },


    // testimonial block
    {
        "name": "Testimonial",
        "justify": "center",
        "align": "center",
        "category": 1,
        "block_image": "media/images/blocks/testimonial.png",
        "color": "#ffffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "I bought all my climbing equipment at 80% off on black friday. The savings were so amazing so I extended my vacation for a week.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#7a7a7a",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "Alex, Climbing Enthusiast",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 8,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#70bcec",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                ],
                "name": "2c",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 9,
                "md": 9,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "stretch": false,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "",
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "primary",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 100,
                        "min_height": 100,
                        "max_width": 200,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": process.env.VUE_APP_MEDIA_URL+"images/unsorted/shoescut.png",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "2c",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 3,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "stretch": false,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "",
                "type": "regular",
                "background_image": ""
            },

            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "primary",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 100,
                        "min_height": 100,
                        "max_width": 200,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": process.env.VUE_APP_MEDIA_URL+"images/unsorted/shoescut.png",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": true,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "2c",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 3,
                "md": 3,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "stretch": false,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "",
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "I bought all my climbing equipment at 80% off on black friday. The savings were so amazing so I extended my vacation for a week.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#7a7a7a",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "Alex, Climbing Enthusiast",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 8,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#70bcec",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                ],
                "name": "2c",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 9,
                "md": 9,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "stretch": false,
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": "",
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true,
        "order": 40
    },

    // Video text blocks
    {
        "category": 1,
        "block_image": "media/images/blocks/contentwithvideo.png",
        "name": "Half Text, Half video Block",
        "color": "#E0E0E0",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "block_contents": [
                    {
                        "id": 84,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Beautiful Designs",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 85,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae mi vulputate, ultrices libero et, pellentesque elit. Praesent consectetur mauris eu placerat lobortis.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 12,
                        "ml": 0,
                        "mr": 0,
                        "mb": 4,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 2,
                        "md": 2,
                        "sm": 2,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 10,
                        "md": 10,
                        "sm": 10,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 2,
                        "md": 2,
                        "sm": 2,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 10,
                        "md": 10,
                        "sm": 10,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 2,
                        "md": 2,
                        "sm": 2,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 10,
                        "md": 10,
                        "sm": 10,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 2,
                        "md": 2,
                        "sm": 2,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "white",
                        "content": "",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "mdi-check",
                        "type": "icon",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 10,
                        "md": 10,
                        "sm": 10,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "white",
                        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    ],
                "name": "Buyone Title, text button 6 col",
                "block_image": "",
                "pt": 8,
                "pl": 0,
                "pr": 0,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "block_contents": [
                    {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "primary",
                    "content": "",
                    "classes": "",
                    "pt": 2,
                    "pl": 2,
                    "pr": 2,
                    "pb": 2,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 500,
                    "max_height": 280,
                    "text_color": "white--text",
                    "href": "#",
                    "value": "https://www.youtube.com/watch?v=KsDUY6Qga0k",
                    "icon": "",
                    "type": "video",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Buyone image 6 col",
                "block_image": "",
                "pt": 8,
                "pl": 0,
                "pr": 0,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true
    },

    // half parallax text block
    {
        "category": 1,
        "block_image": "media/images/blocks/contentboximage1.png",
        "name": "Loyalty Half Text Block",
        "color": "",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "/media/images//page-template/deals-loyalty.jpg",
        "height": 450,
        "inner_blocks": [{
            "id": 18,
            "block_contents": [{
                "id": 50,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "LOYALTY PROGRAM",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 51,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "",
                "content": "Join the loyalty program and collect points the more you buy. We will notify you by email when we have discounts as well as sending you special coupons when you sign up. Free to try and you can unsubscribe anytime.",
                "classes": "body-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 52,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "left",
                "justify": "center",
                "color": "white",
                "content": "FREE SIGN UP",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "FREE SIGN UP",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": true,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Loyalty 6 col block 6 offset rgba back",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 6,
            "md": 6,
            "sm": 8,
            "xs": 12,
            "lg_offset": 6,
            "md_offset": 6,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "rgba(0,0,0,0.5)",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": "height: 100%; ",
            "type": "regular",
            "background_image": ""
        }],
        "container": false
    },

    // text button blocks
    {
        "category": 1,
        "block_image": "media/images/blocks/button.png",
        "name": "Text Button Banner",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 300,
        "inner_blocks": [
            {
                "id": 19,
                "block_contents": [
                    {
                        "id": 53,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "",
                        "content": "LEARN ABOUT OUR PRODUCT RESEARCH",
                        "classes": "display-1",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "id": 54,
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#29b6f6",
                        "content": "Learn More",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#29b6f6",
                        "href": "#",
                        "value": "Learn More",
                        "icon": "mdi-leaf",
                        "type": "button",
                        "large": true,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": true,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "12 Cols banner button block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": true
    },

    // product block
    {
        "category": 1,
        "block_image": "media/images/blocks/product_block.png",
        "name": "Product Block",
        "color": "#FFFFFFFF",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": true,
        "background_image"  : process.env.VUE_APP_MEDIA_URL +"preload/sports/photo-1418662589339-364ad47f98a2.jpg",
        "height": 600,
        "inner_blocks": [
            {

                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "primary",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 200,
                        "min_height": 200,
                        "max_width": 600,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "images/unsorted/shoescut.png",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "BIG DISCOUNTS ON MOUNTAIN CLIMBING GEAR",
                        "classes": "body-1",
                        "pt": 4,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Limited Supply",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#f62d0b",
                        "content": "Learn More",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "Learn More",
                        "icon": "mdi-leaf",
                        "type": "button",
                        "large": true,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 cols",
                "block_image": "",
                "pt": 4,
                "pl": 4,
                "pr": 4,
                "pb": 4,
                "mt": 0,
                "ml": 2,
                "mr": 2,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#ffffffff",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "outlined": true,
                "type": "regular",
                "background_image": ""
            },
            {

                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "primary",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 200,
                        "min_height": 200,
                        "max_width": 600,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "images/unsorted/shoescut.png",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "BIG DISCOUNTS ON MOUNTAIN CLIMBING GEAR",
                        "classes": "body-1",
                        "pt": 4,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Limited Supply",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#f62d0b",
                        "content": "Learn More",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "Learn More",
                        "icon": "mdi-leaf",
                        "type": "button",
                        "large": true,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 cols",
                "block_image": "",
                "pt": 4,
                "pl": 4,
                "pr": 4,
                "pb": 4,
                "mt": 0,
                "ml": 2,
                "mr": 2,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#ffffffff",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "outlined": true,
                "type": "regular",
                "background_image": ""
            },
            {

                "block_contents": [
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "primary",
                        "content": "",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "min_width": 200,
                        "min_height": 200,
                        "max_width": 600,
                        "max_height": 200,
                        "text_color": "white--text",
                        "href": "#",
                        "value": "images/unsorted/shoescut.png",
                        "icon": "",
                        "type": "image",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "BIG DISCOUNTS ON MOUNTAIN CLIMBING GEAR",
                        "classes": "body-1",
                        "pt": 4,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },

                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "left",
                        "justify": "center",
                        "color": "",
                        "content": "Limited Supply",
                        "classes": "body-1",
                        "pt": 0,
                        "pl": 4,
                        "pr": 4,
                        "pb": 0,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 3,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#263238",
                        "href": "#",
                        "value": "",
                        "icon": "",
                        "type": "text",
                        "large": false,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    },
                    {
                        "lg": 12,
                        "md": 12,
                        "sm": 12,
                        "xs": 12,
                        "lg_offset": 0,
                        "md_offset": 0,
                        "sm_offset": 0,
                        "xs_offset": 0,
                        "align": "center",
                        "justify": "center",
                        "color": "#f62d0b",
                        "content": "Learn More",
                        "classes": "",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 0,
                        "mt": 5,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 0,
                        "max_height": 0,
                        "text_color": "#FFFFFFFF",
                        "href": "#",
                        "value": "Learn More",
                        "icon": "mdi-leaf",
                        "type": "button",
                        "large": true,
                        "rounded": false,
                        "small": false,
                        "text": false,
                        "fab": false,
                        "outlined": false,
                        "show_toolbar": false,
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "show_popup": false,
                        "block": false
                    }
                ],
                "name": "4 cols",
                "block_image": "",
                "pt": 4,
                "pl": 4,
                "pr": 4,
                "pb": 4,
                "mt": 0,
                "ml": 2,
                "mr": 2,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#ffffffff",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "outlined": true,
                "background_image": ""
            },
        ],
        "container": true,
        "justify": "center",
        "align": "center",
    },

    //footer blocks
    {
        "category": 1,
        "block_image": "media/images/blocks/footer.png",
        "name": "Footer Block",
        "color": "#263238",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 14,
            "block_contents": [{
                "id": 42,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "LeadSlide",
                "classes": "small",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#ffffff",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Left",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 15,
            "block_contents": [{
                "id": 44,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-facebook",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 45,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-twitter",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 46,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "white",
                "content": "",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "",
                "href": "#",
                "value": "",
                "icon": "mdi-linkedin",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": true,
                "fab": true,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Center",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "list",
            "background_image": ""
        }, {
            "id": 16,
            "block_contents": [{
                "id": 43,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Copyright © LeadSlide",
                "classes": "small",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#ffffff",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "Footer Block Right",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": false
    }
];

export default OuterBlocks;
