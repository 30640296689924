import defaultPages from "../LeadSlide/Pages/DefaultPages";
import axios from "axios";

const state = {
    page: null,
    isPageTemplate: false,
    save_dialog: false,
}

const actions = {
    loadPage: (store, payload) => {
        let page = null;
        store.commit('setLoading', true);
        store.commit('setIsTemplate', payload.isTemplate);
        if(payload.isTemplate)
        {
            page = defaultPages.find(o => o.id === parseInt(payload.id));
            store.commit('setPage', page);
            store.commit('setLoading', false);
        } else {
            // user page
            axios.get('/basic/pages/'+ payload.id + '/').then(function(res){
                store.commit('setPage', res.data);
            }).catch(function(err){
            }).finally(function() {
                store.commit('setLoading', false);
            });
        }
    },
    savePage: (context, payload) => {
        context.commit('setLoading', true);
        context.commit('setSaveDialog', true);
        if(context.state.isPageTemplate)
        {

            axios.post('/basic/pages/', payload.page).then(function(res){
                context.commit('setPage', res.data);
                context.commit('setIsTemplate', false);
            }).catch(function(err){
                console.log('error saving page')
            }).finally(function() {
                context.commit('setLoading', false);
                context.commit('setSaveDialog', false);
            });

        } else  {
            axios.patch('/basic/pages/'+payload.page.id+'/', payload.page).then(function(res){
                context.commit('setPage', res.data);
            }).catch(function(err){
                console.log('error patching page')
            }).finally(function() {
                context.commit('setLoading', false);
                context.commit('setSaveDialog', false);
            })
        }
    },

}

const getters = {
    page: state => {
        return state.page;
    },
    save_dialog: state => {
        return state.save_dialog;
    },
    isPageTemplate: state => {
        return state.isPageTemplate
    }
}

const mutations = {
    setSaveDialog: (state, payload) => {
        state.save_dialog = payload
    },
    setIsTemplate: (state, payload) => {
        state.isPageTemplate = payload
    },
    setPage: (state, payload) => {
        state.page = payload;
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}

