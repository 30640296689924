import Vue from 'vue'

import axios from 'axios/index'
import Notifications from 'vue-notification'

axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    "Content-Type": 'application/json',
    "Authorization": localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined,
    // "Access-Control-Allow-Origin": "*"
};

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

Vue.prototype.$http = axios;

let error_filter = function(res){
    if(res !== undefined && res.status !== undefined && (parseInt(res.status) < 200 || parseInt(res.status) >= 300)){
        console.error(res);
        Vue.prototype.$notify(
            {group: 'notifications',
                title: 'Error ' + res.status,
                text: res.data + ""});
    }
    return res
};

let error_handler = function(res){
    let message = res.message;
    if(res.response !== undefined && res.response.data !== undefined){
        message = res.response.data;
    }
    Vue.prototype.$notify(
        {group: 'notifications',
            title: 'Error',
            type: 'error',
            text: message
        });
    return res;
};

Vue.prototype.$hget = function(url, params){
    return axios.get(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hpost = function(url, params){
    return axios.post(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hdelete = function(url, params){
    return axios.delete(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hpatch = function(url, params){
    return axios.patch(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hput = function(url, params){
    return axios.put(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hrequest = function(config){
    return axios.request(config)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

export const apiHandler = (method, url, params) => {
    switch (method) {
        case 'get':
            return Vue.prototype.$hget(url, params);
        case 'post':
            return Vue.prototype.$hpost(url, params);
        case 'delete':
            return Vue.prototype.$hdelete(url, params);
        case 'patch':
            return Vue.prototype.$hpatch(url, params);
        case 'put':
            return Vue.prototype.$hput(url, params);
        default:
            return Vue.prototype.$hrequest({method: method, url: url, params: params});
    }
}





