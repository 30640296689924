<template>
  <div class="ls-forms mt-4 mb-4">
    <draggable :class="'row'" v-model="form_items"
               @change="changed_position"
               group="people" handle=".handle">

      <v-col cols="12"
             :class="getPaddingClasses(item)"
             class="input_wrapper"
             :sm="!item.cols ? 12: item.cols" v-for="(item, index) in form_items" :key="index">

        <v-text-field
            :style=" !item.rounded ? 'border-radius:0px!important;': ''"
            v-if="item.type !== 'select' && item.type !== 'button' && item.type !== 'textarea'"
            :type="item.type"
            v-model="item.model"
            :label="item.label"
            :solo="item.solo"
            :class="item.custom_class"
            :dense="item.dense"
            :outlined="item.outlined"
            :required="item.required"
        >
        </v-text-field>
        <v-select
            v-if="item.type === 'select'"
            v-model="item.model"
            :style=" !item.rounded ? 'border-radius:0px!important;': ''"
            :rounded="item.rounded"
            :items="item.items"
            :solo="item.solo"
            :dense="item.dense"
            :outlined="item.outlined"
            item-value="id"
            item-text="name"
            :label="item.label"
        ></v-select>

        <v-textarea
            v-if="item.type === 'textarea'"
            :solo="item.solo"
            :rounded="item.rounded"
            :dense="item.dense"
            :outlined="item.outlined"
            :style=" !item.rounded ? 'border-radius:0px!important;': ''"
            :label="item.label"
            v-model="item.model"
        ></v-textarea>

        <v-btn
            v-if="item.type === 'button'"
            :class="(item.xLarge && !item.small && !item.large) ? 'v-size-form--xxl' : ''"
            :color="item.color"
            dark
            :block="item.block"
            :outlined="item.outlined"
            :large="item.large"
            :small="item.small"
            :rounded="item.rounded"
            :style="getButtonStyle(item)"
        >
          <span v-html="item.label"> </span>
        </v-btn>
        <div class="toolbar" v-if="form_editor">
          <v-btn-toggle
              dense
              small
              v-model="no_model"
              background-color="primary"
              dark
              multiple
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click=""
                    class="handle"
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-cursor-move</v-icon>
                </v-btn>
              </template>
              <span>
                    Move Field
                  </span>
            </v-tooltip>



            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="select_field(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Field Properties</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.type !== 'button' && item.type !== 'email'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="remove_item(index, item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon class="red--text">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>
                    Delete Field
                  </span>
            </v-tooltip>
          </v-btn-toggle>
        </div>
      </v-col>

    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    form_editor: {
      type: [Boolean],
      required: false,
      default: false
    },
    items: {
      type: [Array],
      required: true,
    },
    remove_item: {
      type: [Function],
      required: false,
      default: function () {
        console.log("Remove not configured");
      }
    },
    position_change: {
      type: [Function],
      required: false
    },
    select_field: {
      type: [Function],
      required: false,
      default: function () {
        console.log("Select field not configured");
      }
    }
  },
  data() {
    return {
      show_options: false,
      show_dialog: false,
      edit_item: {},
      no_model: null,
      form_items: []
    }
  },
  watch: {
    items(nv) {
      this.form_items = nv;
    }
  },
  created() {
    this.form_items = this.items;
  },
  methods: {
    getPaddingClasses(item) {
      let padding_class = "";
      if(item.pt >= 0)
        padding_class += " pt-" + item.pt.toString()
      if(item.pb >= 0)
        padding_class += " pb-" + item.pb.toString()
      if(item.pr >= 0)
        padding_class += " pr-" + item.pr.toString()
      if(item.pl >= 0)
        padding_class += " pl-" + item.pl.toString()

      return padding_class;
    },
    changed_position(i) {
      this.position_change(i);
    },
    getButtonStyle(item) {
      let style = 'color:'+item.text_color+';';
      if(!item.rounded)
      {
        let radius = 0;
        if(typeof item.borderRadius !== "undefined")
        {
          style += 'border-radius:'+item.borderRadius+'px!important;';
        }
      }
      let shadow = false;
      if(typeof item.box_shadow !== "undefined")
      {
        shadow = item.box_shadow;
      }
      if(!shadow)
      {
        style += 'box-shadow:none!important;';
      }

      return style;
    },
    showOptions(item) {
      this.edit_item = item;
      this.show_dialog = true;
    }
  }
}
</script>

<style lang="scss">
.ls-forms {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
  }
  .v-messages, .v-text-field__details {
    display: none;
  }

  .input_wrapper {
    position: relative;

    .toolbar {
      position: absolute;
      top:-25px;
      left:0px;
      z-index:2;
      display: none;
    }
    &:hover {
      .toolbar {
        display: block;
      }
    }
  }

  input, textarea {
    &:hover {
      cursor: pointer;
    }
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
    border: 1px solid #e3e3e3;
  }

  .v-text-field--outlined, .v-text-field--solo {
    border-radius: 8px;
  }
}

.theme--light {
  .light-border {
    .v-input__control > .v-input__slot fieldset {
      border-color: white;
    }

    input {
      color: white !important;
    }

    .v-label {
      color: white !important;
    }
  }

}


.form-field-editor {
  position: absolute;
  width: 75px;
  top: -25px;

  .v-btn:not(.v-btn--round).v-size--small {
    min-width: 25px;
  }

  .v-btn__content {
    i {
      font-size: 12px;
    }
  }
}
</style>
