import CCP_BLOCKS from './Blocks/Coffee'
export default {
    id: 5,
    image: 'images/popups/5.png',
    name: 'Coffee',
    action_address: 'blank',
    location_address: 'blank',
    max_width: 600,
    min_height:100,
    action_type:1,
    type: 1,
    timing_type:1,
    seconds:30,
    using_image: false,
    color:'#ffffff',
    background_image: '',
    blocks: CCP_BLOCKS
}
