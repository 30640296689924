<template>
  <v-dialog  v-model="formBlock.active" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="form-block-modal" v-if="formBlock">
      <v-toolbar dark color="blue-grey darken-4">
        <v-btn icon dark @click="close_dialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Form Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="close_dialog()">Close Settings</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-12">
        <v-row>
          <v-col v-if="!hide_basic_fields" cols="12" sm="3" class="pa-0">
            <h3 class="mb-4">
              Basic Fields
            </h3>
            <v-row>
              <v-col cols="6" v-for="field in fields">
                <v-card @click="add_field(field)">
                  <v-card-text>
                    <v-row align="center" justify="center" style="height:100px;">
                      <v-col class="pa-0 text-center" cols="12">
                        <div>
                          <v-icon large>
                            {{ field.select_icon }}
                          </v-icon>
                        </div>
                        {{ field.select_label }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" :sm="hide_basic_fields ? 8:5">
            <v-container>
              <v-card>
                <v-card-text>
                  <h3 class="mb-4">
                    Preview
                  </h3>
                  <blocks-functions-form-fields
                      :position_change="change_position"
                      :select_field="select_field"
                      :form_editor="true"
                      :remove_item="remove_item"
                      :items="formBlock.item.items"
                  >
                  </blocks-functions-form-fields>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>

          <v-col cols="12" sm="4" v-show="Object.entries(active_field).length !== 0">
            <h3 class="mb-4">
              Column Properties
            </h3>
            <v-text-field type="number" v-model="active_field.cols" min="1" max="12" label="Columns">

            </v-text-field>

            <v-text-field type="number" v-model="active_field.pt" min="0" max="12" label="Padding Top">

            </v-text-field>
            <v-text-field type="number" v-model="active_field.pb" min="0" max="12" label="Padding Bottom">

            </v-text-field>
            <v-text-field type="number" v-model="active_field.pr" min="0" max="12" label="Padding Right">

            </v-text-field>
            <v-text-field type="number" v-model="active_field.pl" min="0" max="12" label="Padding Left">

            </v-text-field>

            <v-divider class="mt-4 mb-4"></v-divider>
            <h3 class="mb-4">
              Field Properties
            </h3>
            <v-text-field v-model="active_field.label" label="Label">

            </v-text-field>

            <div v-show="active_field.type !== 'button'">
              <v-switch v-model="active_field.solo" label="Boxed"></v-switch>
              <v-switch v-model="active_field.outlined" label="Outlined"></v-switch>
              <v-switch v-model="active_field.dense" label="Dense"></v-switch>
              <v-switch v-model="active_field.required" label="Required Field"></v-switch>
              <v-switch v-model="active_field.rounded" label="Rounded Field"></v-switch>
              <div v-show="active_field.required && !['select', 'date', 'time'].includes(active_field.type)">
                <v-text-field type="number" v-model="active_field.min"  label="Max Requirements">

                </v-text-field>
                <v-text-field type="number" v-model="active_field.min"  label="Max Requirements">

                </v-text-field>
              </div>

              <div v-show="active_field.type === 'select'">
                <h3 class="mb-2 mt-2">
                  Options
                </h3>
                <div class="text-right">
                  <v-icon @click="active_field.items.push({})">
                    mdi-plus
                  </v-icon>
                </div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Your Value</th>
                      <th>Viewable Value</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in active_field.items">
                      <td>
                        <v-text-field dense outlined v-model="item.id">

                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field dense outlined v-model="item.name">

                        </v-text-field>
                      </td>
                      <td>
                        <v-icon @click="active_field.items.splice(index, 1)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
            <div v-if="active_field.type === 'button'">
              <v-switch v-model="active_field.block" label="full width"></v-switch>
              <v-switch v-model="active_field.rounded" label="Rounded Field"></v-switch>
              <v-text-field
                  label="Border Radius"
                  v-model="active_field.borderRadius"></v-text-field>
              <v-switch v-model="active_field.outlined" label="Outlined"></v-switch>
              <v-switch v-model="active_field.xLarge" v-show="!active_field.small && !active_field.large" label="XLarge"></v-switch>
              <v-switch v-model="active_field.large" v-show="!active_field.small && !active_field.xLarge" label="Large"></v-switch>
              <v-switch v-model="active_field.small" v-show="!active_field.large && !active_field.xLarge" label="Small"></v-switch>
              <global-functions-simple-color-picker
              :init_color="active_field.color"
              v-show="!active_field.outlined"
              :color_change="change_background_color"
              :label="'Background'"
              ></global-functions-simple-color-picker>

              <global-functions-simple-color-picker
                  :init_color="active_field.text_color"
                  :color_change="change_text_color"
                  :label="'Color'"
              ></global-functions-simple-color-picker>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";
import GlobalFunctionsSimpleColorPicker from "@/components/Global/Functions/SimpleColorPicker";
import BlocksFunctionsFormFields from "@/components/Blocks/Functions/FormFields";
export default {
  computed: {
    ...mapGetters([
        'formBlock'
    ])
  },
  components: {
    GlobalFunctionsSimpleColorPicker,
    BlocksFunctionsFormFields
  },
  data() {
    return {
      hide_basic_fields: true,
      boxed: false,
      button: null,
      fields: [
        {
          id: 1,
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          label: "Text",
          select_label: "Text",
          select_icon: "mdi-form-textbox",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          rounded: false,
          outlined: true,
          dense: true,
          solo: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "text",
          rules: [],
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 2,
          label: "Email",
          select_label: "Email",
          select_icon: "mdi-email",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          rounded: false,
          outlined: true,
          dense: true,
          solo: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "email",
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 3,
          label: "Number",
          select_label: "Number",
          select_icon: "mdi-counter",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          rounded: false,
          outlined: true,
          dense: true,
          solo: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "number",
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 4,
          label: "Multi Line",
          select_label: "Multi Line",
          select_icon: "mdi-form-textarea",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          rounded: false,
          outlined: true,
          dense: true,
          solo: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "textarea",
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 5,
          label: "Date",
          select_label: "Date",
          select_icon: "mdi-calendar-month",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          solo: false,
          rounded: false,
          outlined: true,
          dense: true,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "date",
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 6,
          label: "Time",
          select_label: "Time",
          select_icon: "mdi-hours-24",
          icon: null,
          minLength: 0,
          outlined: true,
          dense: true,
          solo: false,
          maxLength: 0,
          required: false,
          rounded: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "time",
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 7,
          label: "Drop Down",
          select_label: "Drop Down",
          select_icon: "mdi-form-dropdown",
          icon: null,
          minLength: 0,
          maxLength: 0,
          outlined: true,
          dense: true,
          solo: false,
          required: false,
          rounded: false,
          color: "#696969",
          text_color: "#FFFFFF",
          block: false,
          type: "select",
          items: [
            {
              id:1,
              name: "option 1"
            },
            {
              id:2,
              name: "option 2"
            }
          ],
          rules: []
        },
        {
          cols: 12,
          pl:3,
          pr:3,
          pt:3,
          pb:3,
          id: 8,
          label: "Button",
          select_label: "Button",
          select_icon: "mdi-button-cursor",
          icon: null,
          minLength: 0,
          maxLength: 0,
          required: false,
          rounded: false,
          outlined: true,
          dense: true,
          solo: false,
          borderRadius: 0,
          color: "#f8f8f8",
          text_color: "#696969",
          block: false,
          box_shadow: false,
          type: "button",
          rules: []
        }
      ],
      active_field: {}
    }
  },
  methods: {
    change_position(new_position) {
      this.array_move(this.formBlock.item.items, new_position.moved.oldIndex, new_position.moved.newIndex);
    },
    array_move(arr, fromIndex, toIndex) {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    change_background_color(color) {
      console.log('color change back');
      console.log(color);
      this.active_field.color = color;
    },
    change_text_color(color) {
      this.active_field.text_color = color;
    },
    remove_item(index, item) {
      if(confirm('Are you sure you want to delete this field ?'))
      {
        // console.log(item);
        this.formBlock.item.items.splice(index, 1)
      }
    },
    add_field(field) {
      if(field.type === 'button')
      {
        let existing = this.formBlock.item.items.find(o => o.type === field.type);
        if(existing)
        {
          this.$notify({
              title: 'Error:',
              text: 'You are only allowed one button.',
              group: 'notifications',
              position: 'top right'
          });
          return false;
        }
      }
      this.formBlock.item.items.push(field);
    },
    select_field(field) {
      this.active_field = field;
    },
    close_dialog() {
      this.$store.dispatch('setFormBlock', {"active": false, item: {}})
    },
  }
}
</script>

<style lang="scss">
.form-block-modal {
  .v-messages, .v-text-field__details {
    display: none;
  }
}
</style>