import defaultPages from "../LeadSlide/Pages/DefaultPages";
import axios from "axios";
import defaultEmails from "../LeadSlide/Emails/DefaultEmails"

const state = {
    email: {
        name: 'loading'
    },
    isEmailTemplate: true,
    loading: false,
    templates: defaultEmails
}

const actions = {
    saveEmail: (store, payload) => {

        store.commit('setEmailLoading', true);
        if(store.state.isEmailTemplate)
        {
            axios.post('/business/emails/', payload).then(function(res){
                store.commit('setEmail', res.data);
                store.commit('setIsEmailTemplate', false);
            }).catch(function(err){
                console.log('error saving email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            });

        } else  {
            axios.patch('/business/emails/'+payload.id+'/', payload).then(function(res){
                store.commit('setEmail', res.data);
            }).catch(function(err){
                console.log('error patching email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            })
        }
    },
    loadEmail: (store, payload) => {
        store.commit('setEmailLoading', true);
        store.commit('setIsEmailTemplate', payload.isTemplate);
        if(payload.isTemplate)
        {
            store.commit(
                'setEmail',
                defaultEmails.find(o => o.id === parseInt(payload.id))
            );
            store.commit('setEmailLoading', false);
        } else {
            // user page
            axios.get('/business/emails/'+ payload.id + '/').then(function(res) {
                console.log(res);
                store.commit('setEmail', res.data);
            }).catch(function(err){
                console.log('error loading email')
            }).finally(function() {
                store.commit('setEmailLoading', false);
            });
        }
    },
    loadEmails: store => {

    },
}

const getters = {
    emailTemplates: state => {
        return state.templates
    },
    email: state => {
        return state.email
    },
    isEmailTemplate: state => {
        return state.isEmailTemplate;
    },
    isEmailLoading: state => {
        return state.loading
    },
    defaultEmail: (state, id) => {
        return state.templates.find(o => o.id === id)
    },
}

const mutations = {
    setEmail: (state, payload) => {
        state.email = payload;
    },
    setIsEmailTemplate: (state, bool) => {
        state.isEmailTemplate = bool;
    },
    setEmailLoading: (state, bool) => {
        state.loading = bool;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}