export default {
    skin: "oxide-dark",
    content_css: "dark",
    inline: true,
    menubar: false,
    width: 'auto',
    font_formats:"Open Sans=Open Sans, sans-serif; Arvo=Arvo, sans-serif; Oswald=Oswald, sans-serif; Poppins=Poppins, sans-serif; Prompt=Prompt, sans-serif;PT Sans Caption=PT Sans Caption, sans-serif; Rokkitt=Rokkitt, sans-serif;",
    color_map: [
        "212121",
        "Nero",
        "FFFFFF",
        "White",
        "424242",
        "Charcoal",
        "E0E0E0",
        "Gainsboro",
        "263238",
        "Oxford Blue",
        "00E676",
        "Spring Green",
        "1DE9B6",
        "Turquoise",
        "64FFDA",
        "Aquamarine",
        "00ACC1",
        "Pacific Blue",
        "00E5FF",
        "Aqua",
        "039BE5",
        "Pacific Blue",
        "00B0FF",
        "Deep Sky Blue",
        "40C4FF",
        "Maya Blue"
    ],
    plugins: [
        'textcolor',
        'link'
    ],
    toolbar:
        'fontselect  | link bold italic forecolor backcolor | \
        alignleft aligncenter alignright alignjustify | \
        removeformat | textshadow',
    formats: {
        textshadow: {
            inline: 'span',
            classes: "text--shadow-1"
        },
        h1: { block: 'h1', attributes: {'class': 'text-h2 text-sm-h1'}},
        h4: { block: 'h4', attributes: {'class': 'display-1'}},
        p: { block: 'p', attributes: {'class': 'body-1'}},
        p1: { block: 'p', attributes: {'class': 'body-2'}},
        headline: { block: 'p', attributes: {'class': 'headline'}},
    },
    block_formats: 'Paragraph=p;Paragraph Small=p1;Heading 1=h1;Heading 2=h4;Heading 3=headline;',
    setup : function (ed) {
        ed.ui.registry.addToggleButton('textshadow', {
            title : 'Text shadow',
            icon : 'duplicate-row',
            tooltip: "Text Shadow",
            onAction : function (api) {
                ed.execCommand('mceToggleFormat', !api.isActive(), 'textshadow');
            }
        });
    },
}