<template>
  <v-color-picker
      v-model="color"
      class="ma-2"
      mode="rgba"
      hide-canvas
  ></v-color-picker>
</template>

<script>

export default {
  props: {
    sync_color: {
      type: [Function],
      required: true
    },
    default_color: {
      type: [String],
      required: false,
    }
  },
  data () {
    return {
      rgba: { r: 255, g: 0, b: 255, a: 1 },
    }
  },
  computed: {
    color: {
      get () {
        return this["rgba"]
      },
      set (v) {
        this["rgba"] = v
      },
    },
  },
  components: {

  },
  watch: {
    'color': function(nv){
      this.sync_color(this.rgba);
      console.log(this.rgba);
    }
  },
  created() {
    if(typeof this.default_color !== "undefined" && this.default_color !== "")
    {
      if(typeof this.default_color == "string")
      {
        let find_regex = /[0,9]\s*([^)"]+)/;
        let match = this.default_color.match(find_regex)
        console.log("COLOR MATCH");
        console.log(match);
        if(match.length > 1) {
          let match_parts = match[1].split(',');
          console.log(match_parts);
          console.log(match);
          console.log(this.default_color);
          this.rgba = {
            r: this.getColorDigit(match_parts[0]),
            g: this.getColorDigit(match_parts[1]),
            b: this.getColorDigit(match_parts[2]),
            a: this.getColorDigit(match_parts[3])
          }

        }
      } else if(this.default_color == "object")
      {
        this.rgba = this.default_color;
      }
    }
  },
  methods: {
    getColorDigit(d) {
      if(d === "")
      {
        return 0;
      } else {
        return d.replace(" ", "");
      }
    },
  }
}
</script>
