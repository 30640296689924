// https://vuex.vuejs.org/en/actions.html

import jwt_decode from "jwt-decode"
import defaultEmails from "./LeadSlide/Emails/DefaultEmails"
import defaultPopups from "./LeadSlide/Popups/DefaultPopups"
import defaultPages from "./LeadSlide/Pages/DefaultPages"
import outer_blocks from "./LeadSlide/Blocks/OuterBlocks"
import inner_blocks from "./LeadSlide/Blocks/InnerBlocks"
import content_blocks from "./LeadSlide/Blocks/ContentBlocks"
import axios from "axios";

let actions = {
    apiCall(e, payload) {
        return new Promise((resolve, reject) => {
            axios[payload.type](payload.url, payload.data)
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getEmails: (e) => {
        return new Promise((resolve, reject) => {
            if (defaultEmails.length) {
                resolve(defaultEmails);
            } else {
                reject({
                    'data': 'No Emails Stored'
                })
            }
        });
    },
    getPopups: (e) => {
        return new Promise((resolve, reject) => {
            if (defaultPopups.length) {
                resolve(defaultPopups);
            } else {
                reject({
                    'data': 'No Popups Stored'
                })
            }
        });
    },
    getPages: (e) => {
        return new Promise((resolve, reject) => {
            if (defaultPages.length) {
                resolve(defaultPages);
            } else {
                reject({
                    'data': 'No Pages Stored'
                })
            }
        });
    },
    getPopup: (e, id) => {
        return new Promise((resolve, reject) => {
            let popup = defaultPopups.find(o => o.id === parseInt(id));
            if (popup) {
                resolve(popup);
            } else {
                reject({page: popup})
            }
        });
    },
    getBlocks: (context) => {
        let return_data = {
            outer_blocks: outer_blocks,
            inner_blocks: inner_blocks,
            content_blocks: content_blocks
        };

        return new Promise((resolve, reject) => {
            if (outer_blocks.length) {
                resolve(return_data)
            } else {
                reject({
                    'message': 'Error no outer blocks set',
                    'data': return_data
                })
            }
        });
    },
    register(context, payload) {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.register, payload)
                .then((response) => {
                    if (response.data.token !== '') {
                        context.commit('updateToken', response.data);
                        return resolve(response);
                    } else {
                        return reject(response);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    forgotPassword(context, email) {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.forgotPassword, {email: email})
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    resetPassword(context, payload) {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.resetPassword, payload)
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    updateProfile(context, payload) {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.updateProfile, payload)
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    changePassword(context, payload) {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.changePassword, payload)
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    login: (context, payload) => {

        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.obtainJWT, payload)
                .then((response) => {
                    if (response.data.token !== '') {
                        // Error user has been disabled, profile state = 2
                        context.commit('updateToken', response.data);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    shopifyLogin: (context, payload) => {
        return new Promise((resolve, reject) => {
            console.log("SHOPIFY LOGIN PAYLOAD");
            console.log(payload);
            context.state.$http.post(context.state.endpoints.shopifyLogin, payload)
                .then((response) => {
                    if (response.data.token !== '') {
                        context.commit('updateToken', response.data);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateTokenFromWp: (context, payload) => {
        // const token = payload.token;
        localStorage.setItem('wpToken', payload.token);

        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.verifyJWT, payload)
                .then((response) => {
                    if (response.data.token === '' || !response.data.token) {
                        return reject(response);
                    }
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    loginAsUser: (e, payload) => {
        e.commit('updateUseAsUser', payload);
    },
    loginFromLink: (e, payload) => {
        e.commit('updateLinkLogin', payload);
    },
    cancelUseAsUser: (e) => {
        e.commit('cancelUseAsUser');
    },
    logout: (e) => {
        return Promise.all([
            e.commit('removeToken'),
            e.commit('removeUser')
        ])
    },
    refreshToken: (context) => {
        const payload = {token: this.state.accessToken};
        context.state.$http.post(this.state.endpoints.refreshJWT, payload)
            .then((response) => {
                context.commit('updateUseAsUser', response.data);
                return response;
            })
            .catch((error) => {
                return error;
                // console.log(error)
            })
    },
    inspectToken: () => {
        const token = this.state.accessToken;
        if (token) {
            const decoded = jwt_decode(token);
            const exp = decoded.exp;
            const orig_iat = decode.orig_iat;
            if (exp - (Date.now() / 1000) < 1800 && (Date.now() / 1000) - orig_iat < 628200) {
                this.dispatch('refreshToken')
            } else if (exp - (Date.now() / 1000) < 1800) {
                // DO NOTHING, DO NOT REFRESH
            } else {
                console.log("Login again!");
                //TODO: POPUP LOGIN FORM
                // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
            }
        }
    },
    setResponsive: (context, width) => {
        context.commit('setResponsive', width);
    },
    getSubscriptionPlans: (context) => {
        context.state.$http.get(context.state.endpoints.subscriptionPlans)
            .then((response) => {
                context.commit('setSubscriptionPlans', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    userSubscribe: (context, payload) => {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.subscribe, payload)
                .then((response) => {
                    context.commit('updateToken', response.data)
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    cancelSubscription: (context) => {
        return new Promise((resolve, reject) => {
            context.state.$http.post(context.state.endpoints.cancelSubscription)
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
};

export default actions;
