import apiRequest from '../../helpers/apiHelper';
import urls from '../../helpers/urls';
import AutoResponder from '../LeadSlide/Emails/AutoResponder';

const state = {
    loading: false,
    emailLists: {
        results: [],
    },
    automations: [],
    automation: {},
    defaultStep: {
        delay: null,
        state: 1,
        is_active: true,
        automation_id: null,
        name: 'Auto Responder',
        subject : 'Auto Responder',
        from_name : 'LeadSlide',
        body: AutoResponder,
        automation: {
            public_id: null,
        }
    },
    step: {
        automation_id: null,
        delay: null,
        state: 1,
        is_active: true,
        name: 'Auto Responder',
        subject : 'Auto Responder',
        from_name : 'LeadSlide',
        body: AutoResponder,
        automation: {
            public_id: null,
        }
    },
};

const clone = (obj) => JSON.parse(JSON.stringify(obj));
const addParamsToUrl = (url, params) => {
    let newUrl = url;
    if (params) {
        newUrl += '?';
        Object.keys(params).forEach((key) => {
            newUrl += `${key}=${params[key]}&`;
        });
    }
    return newUrl;
}

const actions = {
    async getEmailLists({ commit }, name) {
        commit('setLoading', true);
        const params = name ? { name } : null;
        const url = addParamsToUrl(urls.emailLists.list, params);
        const response = await apiRequest('GET', url);
        commit('setLoading', false);
        return response;
    },
    async getFormList({ commit }, name) {
        commit('setLoading', true);
        const params = name ? { name } : null;
        const url = addParamsToUrl(urls.forms.list, params);
        const response = await apiRequest('GET', url);
        commit('setLoading', false);
        return response;
    },
    async deleteTemplate({ commit }, template) {
        commit('setLoading', true);
        const url = urls.templates.delete(template.public_id);
        const response = await apiRequest('DELETE', url);
        commit('setLoading', false);
        return response;
    },
    async getAutomations({ commit }, name) {
        commit('setLoading', true);
        const params = name ? { name } : null;
        const url = addParamsToUrl(urls.automations.list, params);
        const response = await apiRequest('GET', url);
        commit('setAutomations', response);
        commit('setLoading', false);
        return response;
    },
    async getAutomation({ commit, state }, public_id) {
        const automation = state.automations?.results?.find((a) => a.public_id === public_id);
        if (automation) {
            commit('setAutomation', automation);
            return { success: true, data: automation };
        }
        commit('setLoading', true);
        const url = urls.automations.detail(public_id);
        try {
            const response = await apiRequest('GET', url);
            commit('setAutomation', response.data);
            commit('setLoading', false);
            return response;
        } catch (error) {
            commit('setLoading', false);
            return { success: false, data }
        }
    },
    async saveAutomation({ commit }, automation) {
        commit('setLoading', true);
        const url = automation.id === 0 || !automation.id ? urls.automations.create : urls.automations.update(automation.public_id);
        const method = automation.id === 0 || !automation.id ? 'POST' : 'PUT';
        const response = await apiRequest(method, url, automation);
        commit('setLoading', false);
        return response;
    },
    async deleteAutomation({ commit }, automation) {
        commit('setLoading', true);
        const url = urls.automations.delete(automation.public_id);
        const response = await apiRequest('DELETE', url);
        if(response.success) {
            commit('removeAutomation', automation);
        }
        commit('setLoading', false);
        return response;
    },

    updateTemplateFromHistory({ commit }, historyTemplate) {
        // first clone the template
        let newTemplate = clone(historyTemplate);
        // then update the template
        commit('setEmailTemplate', newTemplate);
    },
    async getSteps({ commit }, automation_id) {
        commit('setLoading', true);
        const url = addParamsToUrl(urls.automationSteps.list, { automation_id });
        const response = await apiRequest('GET', url);
        commit('setLoading', false);
        return response;
    },
    async getStep({ commit, state }, payload) {
        const { step_id, autoresponder_id } = payload;
        if (step_id === 0 || step_id === '0') {
            const step = clone(state.defaultStep);
            step.id = 0;
            step.automation_id = autoresponder_id;
            step.automation.public_id = autoresponder_id;
            commit('setStep', step);
            return { success: true, data: step };
        }

        commit('setLoading', true);
        const url = urls.automationSteps.detail(step_id);
        const response = await apiRequest('GET', url);
        console.log('getStep response', response);
        commit('setStep', response.data);
        commit('setLoading', false);
        return response;
    },
    async saveStep({ commit }, step) {
        commit('setLoading', true);
        const url = step.id === 0 ? urls.automationSteps.create : urls.automationSteps.update(step.public_id);
        const method = step.id === 0 ? 'POST' : 'PUT';
        const response = await apiRequest(method, url, step);
        if(!response.success) {
            commit('setLoading', false);
            return response;
        }
        commit('setLoading', false);
        return response;
    },
    async updateStep({ commit }, step) {
        commit('setLoading', true);
        const url = urls.automationSteps.update(step.public_id);
        const method = 'PUT';
        const response = await apiRequest(method, url, step);
        if(!response.success) {
            commit('setLoading', false);
            return response;
        }
        commit('setLoading', false);
        return response;
    },
    deleteStep({ commit }, step) {
        commit('setLoading', true);
        const url = urls.automationSteps.delete(step.public_id);
        const response = apiRequest('DELETE', url);
        commit('setLoading', false);
        return response;
    },
};

const getters = {
    loading: (state) => state.loading,
    step: (state) => state.step,
    emailLists: (state) => state.emailLists,
    automations: (state) => state.automations,
    automation: (state) => state.automation,
};

const mutations = {
    setLoading: (state, payload) => {
        state.loading = payload;
    },
    setEmailTemplate: (state, payload) => {
        state.template = payload;
    },
    setStepIds: (state, payload) => {
        const { autoresponder_public_id, id, public_id } = payload;
        state.step.id = id;
        state.step.public_id = public_id;
        window.history.pushState({}, '', `/template-design/edit/${public_id}/${autoresponder_public_id}`);
    },
    setStep: (state, step) => {
        state.step = step;
    },
    setAutomations: (state, payload) => {
        state.automations = payload;
    },
    setAutomation: (state, automation) => {
        state.automation = automation;
    },
    removeAutomation: (state, automation) => {
        console.log(state);
        console.log(automation);
        state.automations.data.results = state.automations.data.results.filter((a) => a.public_id !== automation.public_id);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
