
const state = {
    // position: -1,
    position: -1,
    history: [],
    currentItem: null,
    loading: false,

}


const actions = {
    setHistory: (store, payload) => {
        store.commit('addHistory', payload);
        store.commit('increasePosition');

    },
    clearHistory: store =>  {
        store.commit('clearHistory');
    },
    undoItem: store => {
        let index = store.state.position-1;
        let item = store.state.history[index]

        if(item)
        {
            console.log('item exists');
            store.commit('setItem', item);
            store.commit('decreasePosition');
        }
    },
    redoItem: store => {
        console.log(store.state.position);
        let item = store.state.history[store.state.position+1]
        if(item)
        {
            store.commit('setItem', item);
            store.commit('increasePosition');
        }
    },
    setHistoryLoading: (store, bool) => {
        store.commit('updateHistoryLoading', bool);
    }

}

const getters = {
    historyLength: state => {
        return state.history.length;
    },
    historyItem: state => {
        return state.currentItem;
    },
    position: state => {
        return state.position
    },
    historyLoading: state => {
        return state.loading;
    },
    lastHistoryEdit: state => {
        return state.history[state.history.length - 1];
    }
}

const mutations = {
    setItem: (state, payload) => {
        state.currentItem = JSON.parse(payload);
    },
    increasePosition: state => {
        state.position = state.position + 1;
    },
    decreasePosition: state => {
        state.position = state.position - 1;
    },
    addHistory: (state, payload) => {
        state.history.push(JSON.stringify(payload));
    },
    removeHistoryIndex: (state, index) => {
        state.history.splice(index, 1);
    },
    clearHistory: state => {
        console.log('clearing history');
        state.history = [];
        state.position = -1;
        localStorage.removeItem('ls_campaign_state')

    },
    setLoading: (state, payload) => {
        state.loading = payload;
    },
    updateHistoryLoading: (state, bool) => {
        state.loading = bool;
    },
    setStorageHistory: state => {
        localStorage.setItem('ls_campaign_state', JSON.stringify(state))
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}