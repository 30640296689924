<template>
  <v-dialog v-model="outerBlock.active" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="blue-grey darken-4">
        <v-btn icon dark @click="close_dialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save_and_close()">Save</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader>
          Background
        </v-subheader>
      </v-list>

       <v-container>
           <v-row align="center" justify="center">
               <v-col cols="12" sm="4">
                 <v-switch
                     v-model="outerBlock.item.using_image"
                     label="Using Image"
                 ></v-switch>

                 <div v-if="outerBlock.item.using_image">
                   <v-divider class="mb-2"></v-divider>

                   <h3 class="body-1 font-weight-bold mt-5">
                     Background Image Options
                   </h3>

                   <v-switch
                       v-model="outerBlock.item.parallax"
                       label="Parallax Background"
                   ></v-switch>

                   <v-switch v-model="data_item.image_overlay"
                   label="Add Overlay"
                   ></v-switch>


                   <blocks-functions-simple-color-picker
                       v-if="data_item.image_overlay"
                       :default_color="outerBlock.item.image_overlay_color"
                       :sync_color="sync_color">
                   </blocks-functions-simple-color-picker>
                 </div>
               </v-col>
               <v-col cols="12" sm="8">
                 <blocks-functions-gallery
                     v-if="outerBlock.item.using_image"
                     :change_value="false"
                     :show_title="false"
                     :overlay="data_item.image_overlay"
                     :overlay_color="data_item.image_overlay_color"
                     :item="outerBlock.item"></blocks-functions-gallery>


                 <blocks-functions-simple-color-picker
                     v-if="!outerBlock.item.using_image"
                     :default_color="data_item.color"
                     :sync_color="sync_background_color">
                 </blocks-functions-simple-color-picker>
               </v-col>
           </v-row>
       </v-container>
      <v-divider></v-divider>
      <v-list three-line subheader v-if="false">
        <v-subheader>General</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="outerBlock.item.container"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Container</v-list-item-title>
            <v-list-item-subtitle>
              Sets an inner container inside of the outer block adding side margins to
              the left and right side of the block.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-text-field
                v-model="outerBlock.item.height"
                type="number"
                label="Height"
            ></v-text-field>

          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Height</v-list-item-title>
            <v-list-item-subtitle>
              Set a specific height for the block. Set to automatic by typing '0'
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="pa-2">
        <v-card-actions>
          <v-btn
              color="blue-grey darken-4"
              outlined
              @click="save_and_close()"
          >
            <v-icon class="mr-1">
              mdi-content-save-move
            </v-icon>
            Save Changes
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              outlined
              @click="close_dialog()"
          >
            Cancel Changes
          </v-btn>
        </v-card-actions>


      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";
import BlocksFunctionsGallery from "@/components/Blocks/Functions/Gallery";
import BlocksFunctionsSimpleColorPicker from "@/components/Blocks/Functions/SimpleColorPicker";

export default {
  name: "OuterBlock",
  components: {
    BlocksFunctionsGallery,
    BlocksFunctionsSimpleColorPicker
  },
  computed: {
    ...mapGetters([
        'outerBlock',
        'outerBlockIndex'
    ])
  },
  data () {
    return {
      data_item: {
        color: "rgba(255,255,255,1)",
        image_overlay: false,
        image_overlay_color: "rgba(0, 0, 0, 0.5)"
      }
    }
  },
  created() {
    if(typeof this.outerBlock.item.image_overlay !== "undefined")
    {
      this.data_item.image_overlay = false;
    }

    if(typeof this.outerBlock.item.image_overlay_color !== "undefined")
    {
      this.data_item.image_overlay_color = "rgba(0, 0, 0, 0.5)";
    }

    if( this.outerBlock.item.color &&  this.outerBlock.item.color !== "" && !this.outerBlock.item.color.includes("#"))
    {
      this.data_item.color = this.outerBlock.item.color
    }
  },
  methods: {
    sync_background_color(color) {
      let a = parseFloat(color.a).toFixed(2);
      if(a >= 1)
      {
        a = 1;
      }
      this.data_item.color = 'rgba('+color.r+', '+color.g+', '+color.b+', '+a+')';
    },
    sync_color(color) {
      console.log("COLOR SYNC");
      console.log(color);
      let a = parseFloat(color.a).toFixed(2);
      if(a >= 1)
      {
        a = 1;
      }
      let r = this.getColorDigit(color.r);
      let g = this.getColorDigit(color.g);
      let b = this.getColorDigit(color.b);

      console.log(a);
      this.data_item.image_overlay_color = 'rgba('+color.r+', '+color.g+', '+color.b+', '+a+')';
    },
    getColorDigit(d) {
      if(d === "")
      {
        return 0;
      } else {
        return d;
      }
    },
    save_and_close() {
      let payload = {
        step: 0,
        block: {
          index: this.outerBlockIndex,
          items: [
            this.data_item
          ]
        }
      }

      this.$store.dispatch('updateCampaignBlockObjects', payload);
      this.close_dialog();

    },
    close_dialog()  {
      this.$store.dispatch('resetOuterBlock');
    }
  }
}
</script>