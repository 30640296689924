import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_vm._l((_vm.images),function(image,index){return (_vm.images.length)?_c(VCol,{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"3"}},[_c(VCard,{staticClass:"d-flex",class:{'success pa-1':_vm.item[_vm.changeKey] === image.image || _vm.item.value === image.image },attrs:{"flat":"","tile":""},on:{"click":function($event){return _vm.return_click(image.image)}}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":_vm.get_thumbnail(image),"lazy-src":_vm.get_thumbnail(image),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1):_vm._e()}),_c(VCol,{attrs:{"cols":"12"}},[_c('global-functions-pagination',{attrs:{"watch_search":true,"pagination":_vm.pagination,"return_results":_vm.set_images}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }