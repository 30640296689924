export default [
    {
        type: 'text',
        generalSettings: {
            padding: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            margin: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            type: "text"
        },
        typeSettings: {
            fontSize: '45px',
            color: '#000000',
            bold: false,
            value: '<h1 class="text-h2 text-sm-h1" style="text-align: center;" data-mce-style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif;" data-mce-style="font-family: Open Sans, sans-serif;">BUSINESS SUCCESS EBOOK</span></strong></span></h1><p class="title"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif;" data-mce-style="font-family: Open Sans, sans-serif;"><br data-mce-bogus="1"></span></strong></span></p><p style="text-align: center;" data-mce-style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif;" data-mce-style="font-family: Open Sans, sans-serif;">Free Business Success Ebook, PDF and EPUB</span></strong></span></p>',
            spacing: 0,
            fontFamily: '',
            verticalAlign: true
        },
        backgroundSettings: {
            type: 'image',
            value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
            fixed: false,
            overlay: true,
            overlayValue: 'rgba(0, 0, 0, 0.4)',
            height: '400px',
        },
    },
    {
        type: 'image',
        generalSettings: {
            padding: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            margin: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            type: "image"
        },
        imageSettings: {
            value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
            height: 200,
        },
        backgroundSettings: {
            type: 'color',
            value: '#CCCCCC',
            overlay: false,
            overlayValue: 'rgba(0, 0, 0, 0.4)',
            verticalAlign: false,
            height: 'auto',
            width: '200px',
            float: 'none'
        },
    },
    {
        type: 'text',
        generalSettings: {
            padding: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            margin: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            type: "text"
        },
        typeSettings: {
            fontSize: '45px',
            color: '#000000',
            bold: false,
            value: '<h1 data-mce-style="text-align: center;" class="text-h2 text-sm-h1" style="text-align: center;"><strong><span style="font-family: Open Sans, sans-serif;" data-mce-style="font-family: Open Sans, sans-serif;">This is a banner.</span></strong></h1>',
            spacing: 0,
            fontFamily: '',
            verticalAlign: true
        },
        backgroundSettings: {
            type: 'image',
            value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
            fixed: false,
            overlay: false,
            float: 'none',
            overlayValue: 'rgba(0, 0, 0, 0.4)',
            height: '250px',
        },
    },
];