export default [
    {
        "name": 'Header',
        "description": 'Header Block',
        "category": 'outer',
        "block_image": "",
        "background": "rgba(0,0,0, 1)",
        "inner_blocks" : [
            {
                "pt": 30,
                "pl": 0,
                "pr": 0,
                "pb": 30,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "image",
                        "pt": 15,
                        "pl": 15,
                        "pr": 15,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '/media/images/white-logo.png',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Text Image',
        "description": 'Text and Image Blocks',
        "category": 'outer',
        "block_image": "",
        "background": "#f8f8f8",
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 30,
                "pr": 30,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 1,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "left",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="headline"><span style="color: #424242; font-family: font-family: Open Sans, sans-serif;">Here it is</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="color: #424242; font-family: ont-family: Open Sans, sans-serif;">We are excited to share this deal with you and have you part of our coffee team!</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="color: #424242; font-family: ont-family: Open Sans, sans-serif;">This is a special group designed for coffee lovers and we listen to all of our members to help find new and exciting coffees.' +
                            ' To complete your membership and get the discount just put in the below code.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFFF",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left',
                    },
                ]
            },
        ]
    },
    {
        "name": 'Button Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "background": "#ffffff",
        "pt":15,
        "pb": 15,
        "pl": 15,
        "pr": 15,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 0,
                "pr": 0,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 25,
                        "pl": 0,
                        "pr": 0,
                        "pb": 5,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "value": '<p class="headline" style="text-align:center;"><span style="color: #424242; font-family: font-family: Open Sans, sans-serif;">Membership Code</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'center'
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 5,
                        "value": '<h3 class="display-2" style="text-align:center;"><span style="color: #424242; font-family: font-family: Open Sans, sans-serif;">SYB25</span></h3>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'center'
                    },
                    {
                        "type": "button",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 45,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "font-family": "Open Sans, sans-serif",
                        "value": 'Sign Up',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFF",
                        "color": "#424242",
                        "background": "#424242",
                        "href": "#",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Text Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "background": "#f8f8f8",
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 30,
                "pr": 30,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "left",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 25,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="headline"><span style="color: #424242; font-family: font-family: Open Sans, sans-serif;">Other Products</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "headline",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": true,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 0,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="color: #424242; font-family: ont-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus egestas ' +
                            'viverra. Sed non consequat nulla.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    },
                    {
                        "type": "text",
                        "pt": 15,
                        "pl": 0,
                        "pr": 0,
                        "pb": 15,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style="color: #424242; font-family: ont-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus egestas ' +
                            'viverra. Sed non consequat nulla.</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#494949",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'left'
                    }
                ]
            },
        ]
    },
    {
        "name": 'Button Block',
        "description": 'Single Button Block',
        "category": 'outer',
        "block_image": "",
        "background": "#ffffff",
        "pt":0,
        "pb": 0,
        "inner_blocks" : [
            {
                "pt": 40,
                "pl": 0,
                "pr": 0,
                "pb": 40,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "button",
                        "pt": 55,
                        "pl": 0,
                        "pr": 0,
                        "pb": 55,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": 'CHECK THEM OUT',
                        "font-family": "Open Sans, sans-serif",
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#FFFFFF",
                        "color": "#424242",
                        "background": "#424242",
                        "href": "#",
                    }
                ]
            },
        ]
    },
    {
        "name": 'Footer',
        "description": 'Footer Block',
        "category": 'outer',
        "block_image": "",
        "background": "rgba(0,0,0, 1)",
        "inner_blocks" : [
            {
                "pt": 15,
                "pl": 0,
                "pr": 0,
                "pb": 15,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "span": 2,
                "background": "transparent",
                "custom_class": null,
                "custom_styles": null,
                "align": "center",
                "block_contents": [
                    {
                        "type": "text",
                        "pt": 5,
                        "pl": 5,
                        "pr": 5,
                        "pb": 5,
                        "mt": 0,
                        "ml": 0,
                        "mr": 0,
                        "mb": 0,
                        "value": '<p class="body-1"><span style=\"font-family: Open Sans, sans-serif;\">© LeadSlide 2021</span></p>',
                        "min_width": 0,
                        "min_height": 0,
                        "max_width": 150,
                        "max_height": 0,
                        "classes": "",
                        "text_color": "#ffffff",
                        "href": "#",
                        "bold": false,
                        "italic": false,
                        "underline": false,
                        "shadow": false,
                        "align": 'center'
                    }
                ]
            },
        ]
    },
]