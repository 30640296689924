import DefaultFunnels from "../LeadSlide/Funnels/DefaultFunnels";
import axios from "axios";

const state = {
    loading: false,
    font_list: [
        'Arvo, sans-serif',
        'Arial, sans-serif',
        'Bitter, serif',
        'Crimson Text, serif',
        'Glory, sans-serif',
        'Indie Flower, cursive',
        'Lobster, cursive',
        'Merriweather serif',
        'Montserrat Alternates, sans-serif',
        'Open Sans, sans-serif',
        'Oswald, sans-serif',
        'Poiret One, cursive',
        'Poppins, sans-serif',
        'Prompt, sans-serif',
        'PT Sans Caption, sans-serif',
        'PT Serif, serif',
        'Raleway, sans-serif',
        'Rampart One, cursive',
        'Rokkitt, serif',
        'Smythe, cursive',
        'Roboto, Arial, sans-serif',
        'Ubuntu, Arial, sans-serif',
        'Ubuntu Mono, Courier New, Courier, monospace',
    ],
    colors:[
        {
            color: '#212121',
            label: 'Grey'
        },
        {
            color: '#424242',
            label: 'Grey'
        },
        {
            color: '#616161',
            label: 'Grey'
        },
        {
            color: '#757575',
            label: 'Grey'
        },
        {
            color: '#BDBDBD',
            label: 'Grey'
        },
        {
            color: '#E0E0E0',
            label: 'Grey'
        },
        {
            color: '#263238',
            label: 'Blue Grey'
        },
        {
            color: '#FF5722',
            label: 'Orange'
        },
        {
            color: '#FF7043',
            label: 'Orange'
        },
        {
            color: '#FF8A65',
            label: 'Orange'
        },
        {
            color: '#00C853',
            label: 'Green'
        },
        {
            color: '#00E676',
            label: 'Green'
        },
        {
            color: '#00695C',
            label: 'Teal'
        },
        {
            color: '#00BFA5',
            label: 'Teal'
        },
        {
            color: '#1DE9B6',
            label: 'Teal'
        },
        {
            color: '#64FFDA',
            label: 'Teal'
        },
        {
            color: '#00ACC1',
            label: 'Cyan'
        },
        {
            color: '#00E5FF',
            label: 'Cyan'
        },
        {
            color: '#18FFFF',
            label: 'Cyan'
        },
        {
            color: '#84FFFF',
            label: 'Cyan'
        },
        {
            color: '#01579B',
            label: 'Blue'
        },
        {
            color: '#0277BD',
            label: 'Blue'
        },
        {
            color: '#0288D1',
            label: 'Blue'
        },
        {
            color: '#039BE5',
            label: 'Blue'
        },
        {
            color: '#00B0FF',
            label: 'Blue'
        },
        {
            color: '#40C4FF',
            label: 'Blue'
        },
        {
            color: '#4A148C',
            label: 'Purple'
        },
        {
            color: '#6A1B9A',
            label: 'Purple'
        },
        {
            color: '#7B1FA2',
            label: 'Purple'
        },
        {
            color: '#AA00FF',
            label: 'Purple'
        },
        {
            color: '#D500F9',
            label: 'Purple'
        },
        {
            color: '#C2185B',
            label: 'Pink'
        },
        {
            color: '#D81B60',
            label: 'Pink'
        },
        {
            color: '#E91E63',
            label: 'Pink'
        },
        {
            color: '#FF4081',
            label: 'Pink'
        },
        {
            color: '#FF80AB',
            label: 'Pink'
        },
        {
            color: '#F44336',
            label: 'Red'
        },
        {
            color: '#FF1744',
            label: 'Red'
        },
        {
            color: '#FF5252',
            label: 'Red'
        },
        {
            color: '#FF8A80',
            label: 'Red'
        },
    ],
    default_theme:  {
        id: "leadslide-default",
        name: 'Leadslide Default',
        state: 1,
        title_font: 'Rokkitt, serif',
        title_color: '#424242',
        body_font: 'PT Sans Caption',
        body_color: '#212121',
        button_font:'PT Sans Caption',
        button_color: '#1DE9B6',
        button_text_color: '#FFFFFF',
        colors: [
            {
                color: '#212121',
                label: 'Grey'
            },
            {
                color: '#FFFFFF',
                label: 'White'
            },
            {
                color: '#424242',
                label: 'Grey'
            },
            {
                color: '#E0E0E0',
                label: 'Grey'
            },
            {
                color: '#263238',
                label: 'Blue Grey'
            },
            {
                color: '#00E676',
                label: 'Green'
            },
            {
                color: '#1DE9B6',
                label: 'Teal'
            },
            {
                color: '#64FFDA',
                label: 'Teal'
            },
            {
                color: '#00ACC1',
                label: 'Cyan'
            },
            {
                color: '#00E5FF',
                label: 'Cyan'
            },
            {
                color: '#039BE5',
                label: 'Blue'
            },
            {
                color: '#00B0FF',
                label: 'Blue'
            },
            {
                color: '#40C4FF',
                label: 'Blue'
            },
        ],
        fonts: [
            {
                css: "Open Sans, sans-serif",
                is_google_font: true,
                name: "Open Sans, sans-serif"
            },
            {
                css: "Arvo, sans-serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
            {
                css: "Oswald, sans-serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
            {
                css: "Poppins, sans-serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
            {
                css: "Prompt, sans-serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
            {
                css: "PT Sans Caption, sans-serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
            {
                css: "Rokkitt, serif",
                is_google_font: true,
                name: "Arvo, sans-serif"
            },
        ],
    },
    selected_theme: {},
    themes: [],
    fonts: []
}

const actions = {
    saveTheme: (context, payload) => {
        return new Promise( (resolve, reject) => {
            payload.is_default = false;

            axios.post('basic/themes/', payload).then(function (r) {
                if(r.status === 200)
                {
                    return resolve(r);
                }
                return reject(r);
            }).catch((e) => {
                return reject(e);
            })
        })
    },
    updateTheme: (context, payload) => {
        return new Promise( (resolve, reject) => {
            axios.put('basic/themes/'+payload.id+'/', payload).then(function (r) {
                if(r.status === 200) {
                    return resolve(r);
                } else {
                    return reject(r);
                }

            }).catch((e) => {
                return reject(e);
            })
        })
    },
    getThemes: (context, filter=null) => {
        return new Promise( (resolve, reject) => {
            let return_obj = {};
            axios.get('basic/themes/'+filter).then( (r) => {
                context.commit('setThemes', r.data.results);
                let theme =  r.data.results.find((o) => o.is_default === true);
                context.commit('setTheme', theme);
                return_obj = r;
                return resolve(r);
            }).catch( (e) => {
                return reject(e);
            }).finally(() => {
                return resolve(return_obj);
            })
        })
    },
    getTheme: (context, payload) => {
        return new Promise( (resolve, reject) => {
            let already_loaded = context.state.themes.find( o => o.id === payload.id)
            if(already_loaded) {
                context.commit('setTheme', already_loaded);
                return resolve(already_loaded)
            } else {
                axios.get('basic/themes/' + payload.id + '/').then( (r) => {
                    context.commit('setTheme', r.data);
                    return resolve(r);
                }).catch( (e) => {
                    return reject(e);
                })
            }
        })
    },
    setTheme: (context, theme) => {
        console.log('SETTING THEME');
        console.log(theme);
        context.commit('setTheme', theme);
    },
    updateThemeLoadingStatus: (context, payload) => {
        context.commit('setLoading', payload);
    },
    getSelectedTheme: (store) => {
        axios.get('basic/themes/?is_default=1').then( (r) => {
            store.commit('setSelectedTheme', r.data);
        });
    }
}

const getters = {
    leadslide_theme: (state) => {
        return state.default_theme;
    },
    default_theme: (state) => {
        let user_default = state.themes.find(o => o.is_default === true)
        if(user_default)
            return user_default

        return state.default_theme;
    },
    selected_theme: (state) => {
        // return selected or false
        if(!state.selected_theme)
        {
            if(localStorage.getItem('ls_theme'))
            {
                return JSON.parse(localStorage.getItem('ls_theme'));
            }
            return false;
        }
        console.log("NO SEL");
        console.log(state.selected_theme);

        return state.selected_theme;
    },

    themes: (state) => {
        return state.themes;
    },
    theme_colors: (state) => {
        return state.colors
    },
    font_list: (state) => {
        return state.font_list;
    },
    theme_fonts: (state) => {
        if(!state.selected_theme.fonts)
            return state.default_theme.fonts;
        return state.selected_theme.fonts;
    },
    theme_loading: (s) => {
        return s.loading;
    }

}

const mutations = {
    setSelectedTheme: (state, data) => {
        console.log("Setting selected_theme");
        if(!data.count)
        {
            state.selected_theme = state.default_theme;
        } else {
            state.selected_theme = data.results[0];
        }
        // set local loader for caching theme
        localStorage.setItem('ls_theme', JSON.stringify(state.selected_theme));
    },
    setThemes: (state, themes) => {
        state.themes = themes;
    },
    setTheme: (state, theme) => {
        state.selected_theme = theme;
    },
    setLoading: (state, loading) => {
        state.loading = loading;
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}