<template>
  <v-dialog
      v-model="imageBlock.active"
      :width="800">
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-text>
         <v-container>
             <v-row align="center" justify="center">
                 <v-col cols="12" sm="4">
                   <v-switch
                       label="Hide on mobile"
                       v-model="imageBlock.item.hide_on_mobile"></v-switch>
                 </v-col>
               <v-col cols="12" sm="8">
                 <blocks-functions-gallery :change_value="true" :item="imageBlock.item">

                 </blocks-functions-gallery>
               </v-col>
             </v-row>
         </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer>

        </v-spacer>
        <v-btn
            color="blue-grey darken-4"
            text
            @click="close_dialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import BlocksFunctionsGallery from "@/components/Blocks/Functions/Gallery";

export default {
  components: {BlocksFunctionsGallery},
  computed: {
    ...mapGetters([
        'imageBlock'
    ])
  },
  created() {
    console.log("image model loaded");
  },
  data() {
    return {

    }
  },
  methods: {
    close_dialog() {
      this.$store.dispatch('resetImageBlock');
    }
  }
}
</script>
