import DefaultFunnels from "../LeadSlide/Funnels/DefaultFunnels";
import axios from 'axios'

const allowedTypes = [
    'text',
    'button',
];

const getReplacementText = (content, new_content) => {
    if (!content || typeof content.content !== 'string' || typeof new_content !== 'string') {
        return null;
    }

    let tag = 'p';
    if (content.content.includes('<h1')) {
        tag = 'h1';
        // in the variable new_content every 3 words add \n
        let words = new_content.split(' ');
        let new_words = [];
        let counter = 0;
        words.forEach(word => {
            if(counter === 3) {
                new_words.push('\n');
                counter = 0;
            }
            new_words.push(word);
            counter++;
        });
        new_content = new_words.join(' ');
    } else if(content.content.includes('<h2')) {
        tag = 'h2';
    } else if(content.content.includes('<h3')) {
        tag = 'h3';
    } else if(content.content.includes('<h4')) {
        tag = 'h4';
    } else if(content.content.includes('<h5')) {
        tag = 'h5';
    } else if(content.content.includes('<h6')) {
        tag = 'h6';
    } else if(content.content.includes('<p')) {
        tag = 'p';
    } else if(content.content.includes('<span')) {
        tag = 'span';
    }

    // get class and styles of tag
    // let tagRegExp = new RegExp('<' + tag + '(.*?)>(.*?)<\/' + tag + '>', "g");
    let tagRegExp = new RegExp('<' + tag + '\\s*(.*?)>(.*?)<\/' + tag + '>', "gs");

    let match = tagRegExp.exec(content.content);

    let attributes = match ? match[1] : '';
    let classMatch = attributes.match(/class="(.*?)"/);
    let styleMatch = attributes.match(/style="(.*?)"/);

    let className = classMatch ? classMatch[1] : null;
    let styleName = styleMatch ? styleMatch[1] : null;

    // get class and styles of the span inside h1
    let spanMatch = content.content.match(/<span\s+(.*?)>/);
    let spanAttributes = spanMatch ? spanMatch[1] : '';
    let spanClassMatch = spanAttributes.match(/class="(.*?)"/);
    let spanStyleMatch = spanAttributes.match(/style="(.*?)"/);

    let spanClassName = spanClassMatch ? spanClassMatch[1] : null;
    let spanStyleName = spanStyleMatch ? spanStyleMatch[1] : null;

    return `<${tag} ${className ? `class="${className}"` : ''} ${styleName ? `style="${styleName}"` : ''}><span ${spanClassName ? `class="${spanClassName}"` : ''} ${spanStyleName ? `style="${spanStyleName}"` : ''}>${new_content}</span></${tag}>`;
}


const state = {
    loading: false,
    funnel: null,
    campaign: {steps: []},
    campaigns: [],
    funnels: [],
    dxl: DefaultFunnels,
    default_funnels: DefaultFunnels,
    screen_size: 'pc',
    wordpressUrl: '',
    leadslideUrl: 'https://mycampaign.leadslide.com/',
}

const actions = {
    cloneCampaignBlock: (ctx, payload) => {
        ctx.commit('updateBlocks', payload);
    },
    saveCampaign: (store, payload) => {

    },
    loadCampaigns: (store, payload) =>  {

    },
    setCampaign: (store, campaign) => {
        store.commit('setCampaign', campaign);
    },
    setCampaigns: (store, campaigns) => {
        store.commit('setCampaigns', campaigns);
    },
    dropCampaign: (store) => {
        store.commit('dropCampaign')
    },
    setCampaignLoading: (store, boolean) => {
        store.commit('updateLoading', boolean)
    },
    saveScreenSize: (store, payload) => {
        store.commit('setScreenSize', payload)
    },
    updateCampaignBlocks: (store, obj) => {
        // console.log(obj);
        store.commit('setCampaignBlock', obj);
    },
    updateAndSaveCampaignStep: (store, payload) => {
        const full_campaign = payload.campaign;
        const next_step = payload.next_step;
        console.log(payload)
        full_campaign.step = next_step;
        return new Promise( (resolve, reject) => {
            axios.put('business/campaigns/' + full_campaign.id + '/', full_campaign).then(function(r){
                store.commit('updateStep', next_step);
                return resolve(r);
            }).catch((e) => {
                return reject(e);
            })
        });
    },
    deleteCampaign: (store, campaign) => {
        campaign.state = 2; // disabled
        axios.put('/business/campaigns/'+ campaign.id + '/', campaign).then(function(res) {

        }).catch(function(err){
            console.log('error saving campaign')
        }).finally(function() {
            return true;
        });
    },
    deleteCampaignBlock(store, payload) {
        store.commit('deleteCampaignBlock', payload);
    },
    updateCampaignLoading: (store, boolean) => {
        store.commit('setCampaignLoading', boolean);
    },
    updateCampaignBlockObjects: (store, payload) => {

        store.commit('mutateCampaignBlocks', payload);
    },
    updateCampaignPopup: (store, payload) => {
        store.commit('mutateCampaignPopup', payload);
    },
    deleteBlockContent: (store, payload) => {
        store.commit('mutateDeleteElement', payload);
    }
}

const getters = {
    wordpressUrl: state => {
        if(!state.wordpressUrl && localStorage.getItem('wordpressUrl')) {
            return localStorage.getItem('wordpressUrl');
        }
        return state.wordpressUrl;
    },
    leadslideUrl: state => {
        return state.leadslideUrl;
    },
    funnel: state => {
        return state.funnel
    },
    screen_type: state => {
        return state.screen_size;
    },
    campaignLoading: state => {
        return state.loading
    },
    campaign: state => {
        return state.campaign
    },
    funnels: state => {
        return state.funnels
    },
    defaultFunnels: state => {
        return state.default_funnels
    },
}

const mutations = {
    deleteCampaignBlock: (state, payload) => {
        state.campaign.steps[payload.step].blocks.splice(payload.index, 1);
    },
    mutateUrls: (state, payload) => {
        if(payload.wordpressUrl) {
            localStorage.setItem('wordpressUrl', payload.wordpressUrl);
            state.wordpressUrl = payload.wordpressUrl;
        }

        if(payload.leadslideUrl) {
            state.leadslideUrl = payload.leadslideUrl;
        }
    },
    mutateInnerBlock: (state, payload) => {
        state.loading = true;
        const options = payload.options;

        let optionCounter = 0;
        let blockCounter = 0;
        const block = payload.block;
        let contents = [];
        let step = state.campaign.step;

        if (typeof step === 'undefined') {
            step = 0;
        } else {
            if(step > 2)
            {
                step --;
            }
        }

        /**
         * Check block to see if block_contents is a valid property
         * if not check block.contents
         * then set the variable contents to the value of the property
          */
        if(block.block_contents) {
            contents = block.block_contents;
        } else if(block.contents) {
            contents = block.contents;
        }
        //TODO: Check if step is page, popup or email
        contents.forEach(content => {
            // if content.type not in allowedTypes then continue
            if (!allowedTypes.includes(content.type)) {
                console.log("TYPE NOT ALLOWED");
                console.log(content.type);
                blockCounter++;
                return;
            }

            if(!options[optionCounter] || typeof options[optionCounter] === 'undefined')
            {
                return;
            }

            const new_content = options[optionCounter].text;
            if(new_content === "") {
                // Skip this content, as user chose not to replace it
                optionCounter++;
                blockCounter++;
                return;
            }
            let replacement_content = "";
            let replacement_key = "content";
            if(content.type === 'text') {
                /**
                 * Payload of content should be:
                 * content: {
                 *    content : '<p>Test Example</p>'
                 * }
                 */
                if(typeof content.content === 'undefined') {
                    content.content = content.value;
                }

                replacement_content = getReplacementText(content, new_content);

                replacement_key = "content"
                if(step === 2) {
                    replacement_key = "value"
                }

            } else {
                replacement_key = "value"
                replacement_content = new_content;
            }

            switch(step) {
                case 0:
                    state.campaign.steps[step].blocks[payload.block_index].inner_blocks[payload.inner_block_index].block_contents[blockCounter][replacement_key] = replacement_content;
                    break;
                case 1:
                    state.campaign.steps[step].blocks[payload.block_index].contents[blockCounter][replacement_key] = replacement_content;
                    break;
                case 2:
                    state.campaign.steps[step].blocks[payload.block_index].inner_blocks[payload.inner_block_index].block_contents[blockCounter][replacement_key] = replacement_content;
                    break;
            }

            optionCounter++;
            blockCounter++;
        });
        // state.loading = false;
        setTimeout(function() {
            state.loading = false;
        }, 1000);
    },
    mutateDeleteElement: (state, payload) => {
        state.campaign.steps[state.campaign.step].blocks[payload.blockIndex].inner_blocks[payload.innerBlockIndex].block_contents.splice(
            payload.elementIndex, 1
        )
    },
    mutateCampaignPopup: (state, payload) => {
        for(let key in payload)
        {
            state.campaign.steps[1][key] = payload[key];
        }
    },
    mutateCampaignBlocks: (state, payload) => {
        payload.block.items.forEach(function(obj, key){
            for(let block_key in obj) {
                state.campaign.steps[payload.step].blocks[payload.block.index][block_key] = obj[block_key];
            }
        });
    },
    updateBlocks: (state, payload) => {
        console.log(state.campaign);
        state.campaign.steps[0].blocks.splice(payload.index,0, payload.block)
        state.campaign.steps[0].blocks.join();
    },
    setCampaignLoading: (state, bool) => {
        state.loading = bool;
    },
    setCampaign: (state, obj) => {
      state.campaign = obj;
      localStorage.setItem('campaign', JSON.stringify(obj));
    },
    setCampaigns: (state, array) => {
      state.campaigns = array;
      state.default_funnels = state.dxl;

    },
    dropCampaign: (state) => {
        localStorage.removeItem('campaign');
    },
    updateLoading: (state, bool) => {
        state.loading = bool;
    },
    setScreenSize: (state, size) => {
        state.screen_size = size;
    },
    setCampaignBlock: (state, obj) => {
        let index = obj.index;
        if(!index || typeof index === 'undefined') {
            index = 0;
        }

        state.campaign.steps[index].blocks = obj.step;
    },
    updateStep: (state, step) => {
        console.log('setting step!');
        console.log(step);
        state.campaign.step = step;
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}