import Vue from 'vue';

let state = {
    accessToken:  localStorage.getItem('access_token') ||  '',
    user : {},
    user_groups: {},
    user_groups_names: {},
    user_permissions: {},
    $http: Vue.prototype.$http,
    endpoints: {
        obtainJWT:  '/basic/login/',
        obtainJWTOld:  '/auth/login/',
        refreshJWT: '/auth/refresh/',
        verifyJWT:  '/auth/verify/',
        register:  '/basic/register/',
        shopifyLogin:  '/shopify/login/',
        forgotPassword:  '/basic/forgot-password/',
        resetPassword: '/basic/reset-password/',
        updateProfile: '/basic/update-profile/',
        changePassword: '/basic/update-password/',
        subscriptionPlans: '/basic/get-subscription-plans/',
        subscribe: '/basic/checkout/success/',
        cancelSubscription: '/basic/subscription/cancel/',
    },
    plans: [],
    backend_url: Vue.prototype.$http.defaults.baseURL,
    headers: Vue.prototype.$http.defaults.headers,
    responsive: false,
};

export default state
