<template>
  <v-dialog
      :persistent="false"
      v-model="iconBlock.active" width="400">
    <v-card>
      <v-app-bar
          dark
          color="blue-grey darken-4"
      >

        <v-toolbar-title>
          <v-text-field
              placeholder="Search..."
              v-model="pagination.search.name">
          </v-text-field>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        Color:
        <v-icon style="border: 1px solid white;"
                class="ml-3"
                @click="color_model = true"
                :color="iconBlock.item.text_color">
          mdi-square
        </v-icon>

        <v-dialog  width="300" v-model="color_model">
          <v-card color="#1E1E1E">
            <v-card-text>
              <v-color-picker
                  dark
                  flat
                  v-model="iconBlock.item.text_color"
                  :hide-canvas="false"
                  :hide-inputs="false"
                  mode="hexa"
                  :hide-mode-switch="false"
                  :canvas-height="150"
                  :show-swatches="false"
                  class="mx-auto"
              ></v-color-picker>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                    color="white"
                    @click="color_model = false"
                  >
                    close
                  </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>


      </v-app-bar>

      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-row class="pa-2 config-inner">
          <v-col v-for="icon in icons"
                 cols="4" xs="6" sm="4" md="2"
                 class="text-center mh-25"
          >
            <v-icon
                style="border: 1px solid black"
                class="text-h4"
                @click="iconBlock.item.icon = icon.name">
              {{ icon.name }}
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12">
            <v-divider class="mt-3">

            </v-divider>

              <h3 class="text-h5 font-weight-bold mb-4 mt-8">
                Selected Icon
              </h3>
              <v-icon :style="iconBlock.item.text_color === '#FFFFFF' ? 'background:black;': ''" :color="iconBlock.item.text_color" class="text-h2">
                {{ iconBlock.item.icon }}
              </v-icon>
              {{ iconBlock.item.text_color }}

            <div v-if="iconBlock.item.type === 'button'" class="text-center">
              <v-divider class="mt-3 mb-4">

              </v-divider>
              <v-btn
                    color="blue-grey darken-4"
                    @click="removeIcon()"
                    text
                  >
                    Remove Icon
                  </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <global-functions-pagination
                :watch_search="true"
                :pagination="pagination"
                :return_results="setIcons">

            </global-functions-pagination>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer>

        </v-spacer>
        <v-btn
            color="blue-grey darken-4"
            text
            @click="close_dialog"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import GlobalFunctionsPagination from "@/components/Global/Functions/Pagination.vue";
export default {
  name: "IconBlock",
  computed: {
    ...mapGetters([
        'iconBlock'
    ])
  },
  data() {
    return {
      icons: [],
      search: {name: ""},
      color_model: false,
      search_timeout: null,
      pagination: {
        url: 'icons',
        search: {name: ''},
        limit: 24,
        page: 1
      }
    }
  },
  components: {GlobalFunctionsPagination},
  created() {
  },
  methods: {
    setIcons: function(icons) {
      this.icons = icons;
    },
    close_dialog() {
      this.$store.dispatch('resetIconBlock').then(() => {
        console.log('closed');
      })
    },
    removeIcon() {
      this.iconBlock.item.icon = null;
      this.close_dialog();
    },
  }

}
</script>

<style>
.color_selector {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  background-color: white;
}
</style>