import HalfAndHalf from "./HalfAndHalf"
import VipClub from "./VipClub"
import BlackFriday from "./BlackFriday"
import Discover from "./Discover"
import Coffee from "./Coffee"
import Vegan from "./Vegan"

let default_popups = [
    HalfAndHalf,
    VipClub,
    BlackFriday,
    Discover,
    Coffee,
    Vegan,
];

export default default_popups;
