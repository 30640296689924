export default {
    "id": 3, "name": "Creative Digital",
    "state": 1,
    "image": "images/pages/3.png",
    "blocks": [
        {
        "id": 12,
        "category": 1,
        "block_image": "",
        "name": "Creative Banner Block",
        "color": "transparent",
        "using_image": true,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "images/gallery/creative-banner.jpg",
        "height": 550,
        "inner_blocks": [{
            "id": 20,
            "block_contents": [
                {
                "id": 55,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "CREATIVE DIGITAL SOLUTIONS",
                "classes": "display-4",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "BUY 1 GET 1 FREE",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": true,
                "show_popup": false,
                "block": false
            },
                {
                "id": 56,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur!",
                "classes": "display-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#FFFFFF",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": true,
                "show_popup": false,
                "block": false
            },
                {
                "id": 57,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "#69F0AE",
                "content": "LEARN MORE",
                "classes": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "white--text",
                "href": "#",
                "value": "Sign Up",
                "icon": "",
                "type": "button",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }
            ],
            "name": "Creative Banner Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": false
    },
        {
        "id": 13,
        "category": 1,
        "block_image": "",
        "name": "Creative Banner Title, Text",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 21,
            "block_contents": [{
                "id": 58,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "PROFESSIONAL SERVICES",
                "classes": "display-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": true,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 59,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis nibh et consequat dictum. Donec sodales sollicitudin bibendum.",
                "classes": "headline",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 12,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "12 Cols banner Title Text block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "",
            "background_has_image": true,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    },
        {
        "id": 2,
        "category": 1,
        "block_image": "",
        "name": "Text Icon Block",
        "color": "white",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [{
            "id": 2,
            "block_contents": [{
                "id": 17,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-truck-fast",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 20,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Fast and Free Shipping",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 3,
            "block_contents": [{
                "id": 18,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-bookmark-multiple",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 21,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Up to 80% Selected Merchandise",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }, {
            "id": 4,
            "block_contents": [{
                "id": 19,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "",
                "classes": "display-3",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "mdi-refresh",
                "type": "icon",
                "large": false,
                "rounded": true,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 90,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Easy FREE 30 Day Returns",
                "classes": "display-1",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }, {
                "id": 22,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.",
                "classes": "body-2",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "min_width": 0,
                "min_height": 0,
                "max_width": 0,
                "max_height": 0,
                "text_color": "#263238",
                "href": "#",
                "value": "",
                "icon": "",
                "type": "text",
                "large": false,
                "rounded": false,
                "small": false,
                "text": false,
                "fab": false,
                "outlined": false,
                "show_toolbar": false,
                "bold": false,
                "italic": false,
                "underline": false,
                "shadow": false,
                "show_popup": false,
                "block": false
            }],
            "name": "4 Col Block",
            "block_image": "",
            "pt": 8,
            "pl": 8,
            "pr": 8,
            "pb": 8,
            "mt": 0,
            "ml": 0,
            "mr": 0,
            "mb": 0,
            "lg": 4,
            "md": 4,
            "sm": 4,
            "xs": 12,
            "lg_offset": 0,
            "md_offset": 0,
            "sm_offset": 0,
            "xs_offset": 0,
            "align": "center",
            "justify": "center",
            "color": "transparent",
            "background_has_image": false,
            "custom_class": null,
            "custom_styles": null,
            "type": "regular",
            "background_image": ""
        }],
        "container": true
    },
        {
        "id": 14,
        "category": 1,
        "block_image": "",
        "name": "Creative Inline Form Image",
        "color": "#ffffff",
        "using_image": false,
        "using_video": false,
        "full_height": false,
        "parallax": false,
        "background_image": "",
        "height": 0,
        "inner_blocks": [
            {
                "id": 22,
                "block_contents": [{
                    "id": 60,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "AMAZING SPORTS SHOES",
                    "classes": "display-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 61,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "These are the newest shoes we have that actually help you lose weight and motivate you to move your body. The results and research of these are amazing and we have attached our research for you to see the results.",
                    "classes": "body-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 62,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "Email",
                    "classes": "body-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "TRY IT",
                    "icon": "",
                    "type": "form",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "6 Cols creative inline form and text",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            },
            {
                "id": 23,
                "block_contents": [{
                    "id": 63,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 900,
                    "max_height": 350,
                    "text_color": "white--text",
                    "href": "#",
                    "value": "images/unsorted/outsidebox.jpg",
                    "icon": "",
                    "type": "image",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "6 Cols creative Image Block",
                "block_image": "",
                "pt": 0,
                "pl": 0,
                "pr": 0,
                "pb": 0,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 6,
                "md": 6,
                "sm": 6,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }
        ],
        "container": false
    },
        {
            "category": 1,
            "block_image": "",
            "name": "Testimonial Block Left",
            "color": "#ececec",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "container":true,
            "background_image": "",
            "height": 0,
            "align": "center",
            "justify": "center",
            "inner_blocks": [
                {
                    "name": "4 col, testimonial 1 image",
                    "block_image": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": "height: 100%; ",
                    "type": "regular",
                    "background_image": "",
                    "block_contents": [
                        {
                            "id": 34,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "",
                            "classes": "",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 200,
                            "max_height": 200,
                            "text_color": "white--text",
                            "href": "#",
                            "value":  "images/unsorted/testimonial-thumbs-climber.png",
                            "icon": "",
                            "type": "image",
                            "large": false,
                            "rounded": true,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }
                    ]
                },
                {
                    "name": "4 col, testimonial 1 Text",
                    "block_image": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 8,
                    "md": 8,
                    "sm": 8,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": "height: 100%; ",
                    "type": "regular",
                    "background_image": "",
                    "block_contents": [
                        {
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "I bought all my climbing equipment at 80% off on black Friday. The savings was so amazing so I extended my vacation for a week!",
                            "classes": "headline",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#263238",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        },
                        {
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "Alex, Climbing Enthusiast",
                            "classes": "headline",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#00E5FF",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }
                    ]
                },
            ]
        },
        {
            "category": 1,
            "block_image": "",
            "name": "Testimonial Block Right",
            "color": "#ececec",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "container":true,
            "background_image": "",
            "height": 0,
            "align": "center",
            "justify": "center",
            "inner_blocks": [
                {
                    "name": "4 col, testimonial 1 Text",
                    "block_image": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 8,
                    "md": 8,
                    "sm": 8,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": "height: 100%; ",
                    "type": "regular",
                    "background_image": "",
                    "block_contents": [
                        {
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "I bought all my climbing equipment at 80% off on black Friday. The savings was so amazing so I extended my vacation for a week!",
                            "classes": "headline",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#263238",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        },
                        {
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "Alex, Climbing Enthusiast",
                            "classes": "headline",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 0,
                            "max_height": 0,
                            "text_color": "#00E5FF",
                            "href": "#",
                            "value": "",
                            "icon": "",
                            "type": "text",
                            "large": false,
                            "rounded": false,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }
                    ]
                },
                {
                    "name": "4 col, testimonial 1 image",
                    "block_image": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "background_has_image": false,
                    "custom_class": null,
                    "custom_styles": "height: 100%; ",
                    "type": "regular",
                    "background_image": "",
                    "block_contents": [
                        {
                            "id": 34,
                            "lg": 12,
                            "md": 12,
                            "sm": 12,
                            "xs": 12,
                            "lg_offset": 0,
                            "md_offset": 0,
                            "sm_offset": 0,
                            "xs_offset": 0,
                            "align": "center",
                            "justify": "center",
                            "color": "",
                            "content": "",
                            "classes": "",
                            "pt": 0,
                            "pl": 0,
                            "pr": 0,
                            "pb": 0,
                            "mt": 0,
                            "ml": 0,
                            "mr": 0,
                            "mb": 0,
                            "min_width": 0,
                            "min_height": 0,
                            "max_width": 200,
                            "max_height": 200,
                            "text_color": "white--text",
                            "href": "#",
                            "value":  "images/unsorted/testimonial-thumbs-climber.png",
                            "icon": "",
                            "type": "image",
                            "large": false,
                            "rounded": true,
                            "small": false,
                            "text": false,
                            "fab": false,
                            "outlined": false,
                            "show_toolbar": false,
                            "bold": false,
                            "italic": false,
                            "underline": false,
                            "shadow": false,
                            "show_popup": false,
                            "block": false
                        }
                    ]
                },
            ]
        },

        {
            "id": 15,
            "category": 1,
            "block_image": "",
            "name": "Creative Text Button Banner",
            "color": "#ffffff",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 300,
            "inner_blocks": [{
                "id": 24,
                "block_contents": [{
                    "id": 64,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "LEARN ABOUT OUR PRODUCT RESEARCH",
                    "classes": "display-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#263238",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 65,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "cyan accent-3",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "LEARN MORE",
                    "icon": "mdi-leaf",
                    "type": "button",
                    "large": true,
                    "rounded": true,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": true,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "12 Cols banner button block",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 12,
                "md": 12,
                "sm": 12,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "",
                "background_has_image": true,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
            "container": true
        },
        {
            "id": 8,
            "category": 1,
            "block_image": "",
            "name": "Footer Block",
            "color": "#263238",
            "using_image": false,
            "using_video": false,
            "full_height": false,
            "parallax": false,
            "background_image": "",
            "height": 0,
            "inner_blocks": [{
                "id": 14,
                "block_contents": [{
                    "id": 42,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "LeadSlide",
                    "classes": "small",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Left",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }, {
                "id": 15,
                "block_contents": [{
                    "id": 44,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-facebook",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 45,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-twitter",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }, {
                    "id": 46,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "white",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "",
                    "href": "#",
                    "value": "",
                    "icon": "mdi-linkedin",
                    "type": "button",
                    "large": false,
                    "rounded": true,
                    "small": false,
                    "text": true,
                    "fab": true,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Center",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "list",
                "background_image": ""
            }, {
                "id": 16,
                "block_contents": [{
                    "id": 43,
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "",
                    "content": "Copyright © LeadSlide",
                    "classes": "small",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 0,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                }],
                "name": "Footer Block Right",
                "block_image": "",
                "pt": 8,
                "pl": 8,
                "pr": 8,
                "pb": 8,
                "mt": 0,
                "ml": 0,
                "mr": 0,
                "mb": 0,
                "lg": 4,
                "md": 4,
                "sm": 4,
                "xs": 12,
                "lg_offset": 0,
                "md_offset": 0,
                "sm_offset": 0,
                "xs_offset": 0,
                "align": "center",
                "justify": "center",
                "color": "transparent",
                "background_has_image": false,
                "custom_class": null,
                "custom_styles": null,
                "type": "regular",
                "background_image": ""
            }],
            "container": false
        }]
}
