export default JSON.stringify({
    id: null,
    name: "New PDF",
    pages: [
        {
            order: 1,
            blocks: [
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 2,
                            left: 2,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<h1 class="text-h2 text-sm-h1" style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif; font-size:85px;">BUSINESS SUCCESS EBOOK</span></strong></span></h1><p class="title" style="text-align: center;" data-mce-style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif;" data-mce-style="font-family: Open Sans, sans-serif;">Free Business Success Ebook, PDF and EPUB</span></strong></span></p>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: true
                    },
                    backgroundSettings: {
                        type: 'image',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: true,
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: '550px',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 12,
                            bottom: 4,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        color: '#000000',
                        bold: false,
                        value: '<h4 class="display-1" style="text-align: left;"><span style="font-family: Open Sans, sans-serif; font-size: 24pt; font-weight:800;">OVERVIEW</span></h4>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<p style="font-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed enim mauris. Sed eu libero at elit sollicitudin posuere. Nunc nunc felis, molestie sed sollicitudin id, lacinia consectetur nisl. Proin aliquet vitae sem ut pulvinar. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean justo neque, hendrerit a commodo ut, fringilla quis lacus.</p><p style="font-family: Open Sans, sans-serif;">Vivamus rutrum placerat velit eu faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur ut nisi dignissim, malesuada sem sit amet, ultrices nulla. In a tellus eros. Suspendisse finibus augue ex, ac condimentum felis aliquet ut. </p><p style="font-family: Open Sans, sans-serif;">Aenean vestibulum ex erat, eget elementum ipsum fringilla eu. Phasellus sed augue porttitor, porta eros sed, dictum justo. </p>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
            ]
        },
        {
            order: 1,
            blocks: [
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 5,
                            left: 5,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<h1 class="text-h2 text-sm-h1" style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif; font-size:45px;">HEADING 1</span></strong></span></h1>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: true
                    },
                    backgroundSettings: {
                        type: 'color',
                        value: 'rgba(0, 253, 255, 1)',
                        fixed: false,
                        overlay: false,
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: '200px',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 12,
                            bottom: 4,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        color: '#000000',
                        bold: false,
                        value: '<h4 class="display-1" style="text-align: left;" data-mce-style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 24pt; font-weight:800;" data-mce-style="font-family: Open Sans, sans-serif; font-size: 24pt;">OVERVIEW</span></h4>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<p style="font-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed enim mauris. Sed eu libero at elit sollicitudin posuere. Nunc nunc felis, molestie sed sollicitudin id, lacinia consectetur nisl. Proin aliquet vitae sem ut pulvinar. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean justo neque, hendrerit a commodo ut, fringilla quis lacus.</p><p style="font-family: Open Sans, sans-serif;">Vivamus rutrum placerat velit eu faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur ut nisi dignissim, malesuada sem sit amet, ultrices nulla. In a tellus eros. Suspendisse finibus augue ex, ac condimentum felis aliquet ut. </p><p style="font-family: Open Sans, sans-serif;">Aenean vestibulum ex erat, eget elementum ipsum fringilla eu. Phasellus sed augue porttitor, porta eros sed, dictum justo. </p><p style="font-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed enim mauris.</p>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
            ]
        },
        {
            order: 1,
            blocks: [
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 5,
                            left: 5,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<h1 class="text-h2 text-sm-h1" style="text-align: center;"><span style="color: rgb(255, 255, 255);" data-mce-style="color: #ffffff;"><strong><span style="font-family: Open Sans, sans-serif; font-size:45px;">HEADING 2</span></strong></span></h1>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: true
                    },
                    backgroundSettings: {
                        type: 'color',
                        value: 'rgba(0, 253, 255, 1)',
                        fixed: false,
                        overlay: false,
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: '200px',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 12,
                            bottom: 4,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        color: '#000000',
                        bold: false,
                        value: '<h4 class="display-1" style="text-align: left;" data-mce-style="text-align: center;"><span style="font-family: Open Sans, sans-serif; font-size: 24pt; font-weight:800;" data-mce-style="font-family: Open Sans, sans-serif; font-size: 24pt;">OVERVIEW</span></h4>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
                {
                    type: 'text',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "text"
                    },
                    typeSettings: {
                        fontSize: '45px',
                        color: '#000000',
                        bold: false,
                        value: '<p style="font-family: Open Sans, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed enim mauris. Sed eu libero at elit sollicitudin posuere. Nunc nunc felis, molestie sed sollicitudin id, lacinia consectetur nisl. Proin aliquet vitae sem ut pulvinar. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean justo neque, hendrerit a commodo ut, fringilla quis lacus.</p><p style="font-family: Open Sans, sans-serif;">Vivamus rutrum placerat velit eu faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur ut nisi dignissim, malesuada sem sit amet, ultrices nulla. In a tellus eros. Suspendisse finibus augue ex, ac condimentum felis aliquet ut. </p><p style="font-family: Open Sans, sans-serif;">Aenean vestibulum ex erat, eget elementum ipsum fringilla eu. Phasellus sed augue porttitor, porta eros sed, dictum justo. </p>',
                        spacing: 0,
                        fontFamily: '',
                        verticalAlign: false
                    },
                    backgroundSettings: {
                        type: 'none',
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        fixed: false,
                        overlay: false,
                        float: 'none',
                        overlayValue: 'rgba(0, 0, 0, 0.4)',
                        height: 'auto',
                    },
                },
                {
                    type: 'image',
                    generalSettings: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            right: 12,
                            left: 12,
                        },
                        margin: {
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                        },
                        type: "image"
                    },
                    imageSettings: {
                        value: '/media/preload/outdoor/benjamin-voros-phIFdC6lA4E-unsplash.jpg',
                        height: 350,
                    },
                    backgroundSettings: {
                        type: 'color',
                        value: '#FFFFFF',
                        overlay: false,
                        overlayValue: 'rgba(255, 255, 255, 1)',
                        verticalAlign: false,
                        height: 'auto',
                        float: 'none'
                    },
                }
            ]
        },
    ],
});