import axios from "axios";
import defaultPopups from "../LeadSlide/Popups/DefaultPopups";

const state = {
    loading: false,
    popup: null,
    isPageTemplate: false,
    save_dialog: false,
}

const actions = {
    loadPopup: (store, payload) => {
        let popup = null;
        store.commit('setLoading', true);
        store.commit('setIsTemplate', payload.isTemplate);
        if(payload.isTemplate)
        {
            popup = defaultPopups.find(o => o.id === parseInt(payload.id));
            if(!popup.id)
            {
                popup.id = 0;
            }
            store.commit('setPopupIsTemplate', true);
            store.commit('setPopup', popup);
            store.commit('setLoading', false);
        } else {
            // user page
            axios.get('/basic/popups/'+ payload.id + '/').then(function(res){

                store.commit('setPopup', res.data);
                store.commit('setPopupIsTemplate', false);
            }).catch(function(err){
            }).finally(function() {
                store.commit('setLoading', false);
            });
        }
    },
    savePopup: (context, payload) => {
        context.commit('setLoading', true);
        context.commit('setSaveDialog', true);
        if(context.state.isPageTemplate)
        {

            axios.post('/basic/popups/', payload.popup).then(function(res){
                context.commit('setPopup', res.data);
                context.commit('setPopupIsTemplate', false);
            }).catch(function(err){
                console.log('error saving popup')
            }).finally(function() {
                context.commit('setPopupLoading', false);
            });
        } else  {

            axios.patch('/basic/popups/'+payload.popup.id+'/', payload.popup).then(function(res){
                context.commit('setPopup', res.data);
            }).catch(function(err){
                console.log('error patching page')
            }).finally(function() {
                context.commit('setPopupLoading', false);
            })
        }
    },
}

const getters = {
    popup: state => {
        return state.popup;
    },
    loadingPopup: state => {
        return state.loading;
    },
    isPopupTemplate: state => {
        return state.isPageTemplate
    }
}

const mutations = {
    setSaveDialog: (state, payload) => {
        state.save_dialog = payload
    },
    setPopupIsTemplate: (state, payload) => {
        state.isPageTemplate = payload
    },
    setPopup: (state, payload) => {
        state.popup = payload;
    },
    setPopupLoading: (state, payload) => {
        state.loading = payload;
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}