import apiRequest from '../../helpers/apiHelper';
import urls from '../../helpers/urls';

const state = {
    loading: false,
    forms: {
        count: 0,
        results: [],
    },
    form: {
        public_id: null,
        state: 1,
        name: 'New Form',
        description: '',
        fields:[]
    },
    defaultForm: {
        public_id: null,
        state: 1,
        name: 'New Form',
        width: 450,
        height: 400,
        description: '',
        fields: [
            {
                id: 4,
                label: 'Email',
                type: 'email',
                cols: 8,
                required: true,
                outlined: true,
                solo: false,
                dense: false,
                rounded: true,
                pl: 3,
                pr: 1,
                pt: 2,
                pb: 2,
            },
            {
                id: 5,
                cols: 4,
                label: 'Submit',
                type: 'button',
                block: true,
                outlined: false,
                rounded: false,
                xLarge: true,
                borderRadius: 5,
                color: '#0098ea',
                text_color: '#ffffff',
                pl: 1,
                pr: 3,
                pt: 2,
                pb: 2,
            }
        ],
    },
    fields: [
        {
            id: 1,
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            label: "Text",
            select_label: "Text",
            select_icon: "mdi-form-textbox",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            rounded: false,
            outlined: true,
            dense: true,
            solo: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "text",
            rules: [],
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 2,
            label: "Email",
            select_label: "Email",
            select_icon: "mdi-email",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            rounded: false,
            outlined: true,
            dense: true,
            solo: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "email",
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 3,
            label: "Number",
            select_label: "Number",
            select_icon: "mdi-counter",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            rounded: false,
            outlined: true,
            dense: true,
            solo: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "number",
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 4,
            label: "Multi Line",
            select_label: "Multi Line",
            select_icon: "mdi-form-textarea",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            rounded: false,
            outlined: true,
            dense: true,
            solo: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "textarea",
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 5,
            label: "Date",
            select_label: "Date",
            select_icon: "mdi-calendar-month",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            solo: false,
            rounded: false,
            outlined: true,
            dense: true,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "date",
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 6,
            label: "Time",
            select_label: "Time",
            select_icon: "mdi-hours-24",
            icon: null,
            minLength: 0,
            outlined: true,
            dense: true,
            solo: false,
            maxLength: 0,
            required: false,
            rounded: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "time",
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 7,
            label: "Drop Down",
            select_label: "Drop Down",
            select_icon: "mdi-form-dropdown",
            icon: null,
            minLength: 0,
            maxLength: 0,
            outlined: true,
            dense: true,
            solo: false,
            required: false,
            rounded: false,
            color: "#696969",
            text_color: "#FFFFFF",
            block: false,
            type: "select",
            items: [
                {
                    id:1,
                    name: "option 1"
                },
                {
                    id:2,
                    name: "option 2"
                }
            ],
            rules: []
        },
        {
            cols: 12,
            pl:3,
            pr:3,
            pt:3,
            pb:3,
            id: 8,
            label: "Button",
            select_label: "Button",
            select_icon: "mdi-button-cursor",
            icon: null,
            minLength: 0,
            maxLength: 0,
            required: false,
            rounded: false,
            outlined: true,
            dense: true,
            solo: false,
            borderRadius: 0,
            color: "#f8f8f8",
            text_color: "#696969",
            block: false,
            box_shadow: false,
            type: "button",
            rules: []
        }
    ],
    publicForms: {},
    publicForm: null,
};

const clone = (obj) => JSON.parse(JSON.stringify(obj));
const addParamsToUrl = (url, params) => {
    let newUrl = url;
    if (params) {
        newUrl += '?';
        Object.keys(params).forEach((key) => {
            newUrl += `${key}=${params[key]}&`;
        });
    }
    return newUrl;
}

const actions = {
    updateFormFromHistory({ commit }, historyForm) {
        commit('setForm', clone(historyForm));
    },
    async getForms({ commit }, params) {
        commit('setLoading', true);
        const url = addParamsToUrl(urls.forms.list, params);
        const response = await apiRequest('GET', url);
        commit('setLoading', false);
        if(response.success) {
            commit('setForms', response.data);
        }
        return response;
    },
    async getForm({ commit }, id) {
        commit('setLoading', true);
        if(id === '0' || id === 0) {
            commit('setForm', clone(state.defaultForm));
            commit('setLoading', false);
            return { success: true, data: state.defaultForm };
        }

        const url = urls.forms.detail(id);
        const response = await apiRequest('GET', url);
        commit('setLoading', false);
        if(response.success) {
            commit('setForm', response.data);
        }
        return response;
    },
    async updateForm({ commit }, payload) {
        commit('setLoading', true);
        const method = payload.id ? 'PUT' : 'POST';
        const url = payload.id ? urls.forms.update(payload.public_id) : urls.forms.create;
        const response = await apiRequest(method, url, payload);
        commit('setLoading', false);
        return response;
    },
    async deleteForm({ commit }, id) {
        commit('setLoading', true);
        const response = await apiRequest('DELETE', urls.forms.delete(id));
        commit('setLoading', false);
        return response;
    },
    async getPublicForm({ commit }, public_id) {
        commit('setLoading', true);
        const response = await apiRequest('GET', urls.public.forms.list + `?id=${public_id}`);
        if(response.success) {
            commit('setPublicForm', response.data);
        }
        commit('setLoading', false);
        return response;
    },
    async getSubmissions({ commit }, id) {
        commit('setLoading', true);
        const response = await apiRequest('GET', urls.forms.submissions.list(id));
        commit('setLoading', false);
        return response;
    },
    async deleteSubmission({ commit }, payload) {
        const { id, submission_id } = payload;
        commit('setLoading', true);
        const response = await apiRequest('PUT', urls.forms.submissions.delete(id, submission_id));
        commit('setLoading', false);
        return response;
    }
};

const getters = {
    loading: (state) => state.loading,
    form: (state) => state.form,
    publicForm: (state) => state.publicForm,
    forms: (state) => state.forms,
    fields: (state) => state.fields,
};

const mutations = {
    setLoading: (state, payload) => {
        state.loading = payload;
    },
    setForm: (state, payload) => {
        state.form = payload;
    },
    setForms: (state, payload) => {
        state.forms = payload;
    },
    setPublicForm: (state, payload) => {
        if(payload.results.length > 0) {
            const form = payload.results[0];
            form.items = form.fields;
            state.publicForm = form;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
