// https://vuex.vuejs.org/en/getters.html
import innerBlocks from './LeadSlide/Blocks/InnerBlocks'
import outerBlocks from './LeadSlide/Blocks/OuterBlocks'

export default {
    outerBlocks: () => {
        return outerBlocks
    },
    inner_blocks: () => {
        return innerBlocks;
    },
    default_col_sizes: () => {
        return [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ]
    },
    access_token: (state) => 'jwt '+ state.accessToken,
    user_public_id: (state) => {
        if(state.user) {
            return state.user.profile_user.api_key;
        }

        if(!!localStorage.getItem('user'))
            return JSON.parse(localStorage.getItem('user')).profile_user.api_key;
        else return {};
    },
    user: (state) => {
        if(state.user) {
            return state.user;
        }

        if(!!localStorage.getItem('user'))
            return JSON.parse(localStorage.getItem('user'));
        else return {};
    },
    buffer_user: (state) =>{
        if(state.user) {
            return state.user;
        }
        if(!!localStorage.getItem('_previous_details'))
            return JSON.parse(localStorage.getItem('_previous_details'));
        else return {};
    },
    user_groups: (state) => {
        if(state.user) {
            return state.user.groups;
        }
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.groups;
            return temp.map(x => x.id);
        }
        else return {};
    },
    user_groups_names: () => {
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.groups;
            return temp.map(x => x.name);
        }
        else return {};
    },
    user_permissions: () => {
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.user_permissions;
            return temp.map(x => x.id);
        }
        else return {};
    },
    plans: (state) => {
        return state.plans;
    }


}
