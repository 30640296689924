import axios from "axios";

const state = {
    campaign: {},
    show_popup: false,
    popup: {}
}

const actions = {
    liveSubscribe ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('basic/live/subscribe', payload)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    return reject(error)
                });
        });
    },
    livePopup ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`basic/wp/short-code/${payload.id}/${payload.key}`, payload)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    return reject(error)
                });
        });
    },
}

const getters = {
    liveCampaign: state => {
        return state.campaign;
    },
    liveCampaignId: state => {
        return state.campaign.id;
    },
    liveCampaignPublicID: state => {
        return state.campaign.public_id;
    },
    liveShowPopup: state => {
        return state.show_popup;
    },
    livePopup: state => {
        return state.popup;
    }
}

const mutations = {
    setLiveCampaign(state, campaign) {
        state.campaign = campaign;
        state.popup = campaign.steps[1];
    },
    setLivePopupDisplay(state, bool) {
        state.show_popup = bool;
    },
    setLiveCampaignPublicID(state, id) {
        state.campaign.public_id = id;
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}