export default {
    id: 4,
    image: 'images/popups/discover.png',
    name: 'Discover',
    action_address: 'blank',
    location_address: 'blank',
    max_width: 600,
    min_height:100,
    action_type:1,
    type: 1,
    timing_type:1,
    seconds:30,
    using_image: true,
    color:'transparent',
    background_image: '/api-lcmedia/preload/food/EnF7DhHROS8OMEp2pCkx_Dufer.jpg',
    blocks: [
        {
            cols:6,
            "lg": 12,
            "md": 12,
            "sm": 12,
            "xs": 12,
            "lg_offset": 6,
            "md_offset": 6,
            "sm_offset": 6,
            "xs_offset": 0,
            using_image: false,
            color:'transparent',
            background_image: '',
            contents: [
                {
                    "justify": "center",
                    "align": "center",
                    "color": "",
                    "content": "Discover the latest food and drinks around you",
                    "classes": "headline",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 12,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "justify": "center",
                    "align": "center",
                    "color": "",
                    "content": "Find the best reviewed restaurants near you by signing up today.",
                    "classes": "body-1",
                    "pt": 0,
                    "pl": 0,
                    "pr": 0,
                    "pb": 0,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#ffffff",
                    "href": "#",
                    "value": "",
                    "icon": "",
                    "type": "text",
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": false,
                    "italic": false,
                    "underline": false,
                    "shadow": false,
                    "show_popup": false,
                    "block": false
                },
                {
                    "lg": 12,
                    "md": 12,
                    "sm": 12,
                    "xs": 12,
                    "lg_offset": 0,
                    "md_offset": 0,
                    "sm_offset": 0,
                    "xs_offset": 0,
                    "align": "center",
                    "justify": "center",
                    "color": "transparent",
                    "content": "",
                    "classes": "",
                    "pt": 0,
                    "pl": 5,
                    "pr": 5,
                    "pb": 5,
                    "mt": 0,
                    "ml": 0,
                    "mr": 0,
                    "mb": 4,
                    "min_width": 0,
                    "min_height": 0,
                    "max_width": 0,
                    "max_height": 0,
                    "text_color": "#FFFFFFF",
                    "href": "#",
                    "value": "Submit",
                    "icon": "",
                    "type": "form",

                    "items": [
                        {
                            label: 'Email Address',
                            icon: null,
                            required: true,
                            outlined: true,
                            solo: false,
                            dense: true,
                            color: '#ECEFF1',
                            minLength: 5,
                            custom_class: 'light-border',
                            maxLength: 20,
                            type: 'email',
                            join: true,
                            item: {
                                label: 'Sign Up',
                                color:'#9ecdb2',
                                text_color: '#FFFFFF',
                                icon: null,
                                rounded: false,
                                block: true,
                                outlined: false,
                                type: 'button',
                                rules: []
                            },
                            model: "",
                            rules: [
                                v => !!v || 'E-mail is required',
                                v => /.+@.+/.test(v) || 'E-mail must be valid',
                            ]
                        }
                    ],
                    "large": false,
                    "rounded": false,
                    "small": false,
                    "text": false,
                    "fab": false,
                    "outlined": false,
                    "show_toolbar": false,
                    "bold": true,
                    "italic": false,
                    "underline": false,
                    "shadow": true,
                    "show_popup": false,
                    "block": false
                },
            ]
        },
    ]
}
